import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { classNames } from '@/helpers/common';
import { colors } from '@/styles/colors';
import { getBoolean, getItem } from '@/helpers/common/localStorage';
import { REACT_APP_AUTH, REACT_APP_USER, REACT_CART } from '@/utils/config';
import { AuthUser } from '@/model/AuthUser';
import { NavigationMenu } from 'src/types';
import ClientNotification from '@/model/ClientNotification';
import { IconHamburger } from '@/assets/icons/IconHamburger';
import React, { useState } from 'react';
import Cart from '@/features/core/cart/components/Cart';
import { Dropdown } from '../Dropdown';
import { Menu } from '../Menu';
import { Bell, CaretDown, Logout, ShoppingCart, Sizes, Ticket, User } from '../../assets/icons';
import logoFullBlack from '../../assets/images/logo-full-black.png';
import logoFullWhite from '../../assets/images/logo-full-white.png';
import logo from '../../assets/images/logo.svg';
// import { GiTicket } from 'react-icons/gi';

interface HeaderProps {
  homepage?: boolean;
  notifications: ClientNotification[];
  goTo: (href: string) => void;
  handleOnSignIn?: () => void;
  handleOnSignUp?: () => void;
  handleOnCart?: () => void;
  handleOnLogout?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  homepage,
  notifications,
  goTo,
  handleOnSignIn,
  handleOnSignUp,
  handleOnCart,
  handleOnLogout,
}) => {
  const navigation: NavigationMenu[] = [
    { name: 'Início', href: '/', current: true },
    { name: 'Quem somos', href: '/quemsomos', current: false },
    {
      name: 'Contato e suporte',
      href: '/contato-suporte',
      current: false,
    },
    { name: 'Políticas', href: '/politicas', current: false },
  ];
  const signed = getBoolean(String(REACT_APP_AUTH));
  const user: AuthUser = getItem(String(REACT_APP_USER));
  const getCart = (): Cart => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        discountCoupon: undefined,
        voucher: undefined,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as Cart;

    return newCart;
  };

  const cart = getCart();

  const getLettersName = (name: string): string => {
    const names = name ? name.split(' ') : [''];
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header
      className={classNames(
        homepage ? 'bg-header absolute top-0 left-0' : 'bg-white',
        'w-full shadow-xs',
      )}
    >
      <div className="container mx-auto h-28 flex justify-between items-center">
        <div className="flex flex-1 items-center sm:items-stretch sm:justify-start">
          <div className="flex flex-shrink-0 items-center">
            <a href="#" onClick={() => goTo('/')}>
              <span className="sr-only">Banca do Ingresso</span>
              <img className="block w-auto lg:hidden" src={logo} alt="Banca do Ingresso" />
              <img
                className="w-auto h-[80px] hidden lg:block"
                src={homepage ? logoFullBlack : logoFullWhite}
                alt="Banca do Ingresso"
              />
            </a>
          </div>
          <div className="hidden sm:ml-6 sm:flex items-center">
            <nav className="mr-auto ml-8 space-x-8">
              {navigation.map(item => (
                <a
                  key={item.name}
                  href="#"
                  className={classNames(
                    homepage ? 'text-white' : 'text-black',
                    'font-dmsans font-normal',
                  )}
                  onClick={() => goTo(item.href)}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <div>
          <ul className="flex items-center space-x-1 md:space-x-5">
            {signed && (
              <li className="border-white border-solid border-1 bg-transparent p-1 rounded-lg">
                <a
                  href="#"
                  className={classNames(
                    homepage ? 'text-white' : ' text-neutro-b-400',
                    'font-dmsans font-normal',
                    'uppercase',
                  )}
                  role="menuitem"
                  tabIndex={-1}
                  onClick={() => goTo('/ingressos')}
                >
                  Meus Ingressos
                  {/* <span className="sr-only">Meus ingressos</span> */}
                  {/* <GiTicket
                      color={homepage ? colors.white : colors.graydark}
                      size={Sizes.medium}
                    /> */}
                </a>
              </li>
            )}
            <li>
              <div className="relative">
                <button
                  type="button"
                  className="flex justify-center items-center w-[46px] h-[46px] rounded-full text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={handleOnCart}
                >
                  <span className="sr-only">Meu carrinho</span>
                  <ShoppingCart
                    color={homepage ? colors.white : colors.graydark}
                    size={Sizes.medium}
                  />
                </button>
                <div
                  className="absolute top-0 right-0 w-[22px] h-[22px] rounded-full bg-primary-500 text-white
                flex justify-center items-center"
                  style={{
                    display: `${cart.amountItens > 0 ? 'flex' : 'none'}`,
                  }}
                >
                  {cart.amountItens}
                </div>
              </div>
            </li>
            {signed ? (
              <>
                <li className="relative">
                  <DropdownMenu.Root>
                    <DropdownMenu.DropdownMenuTrigger asChild>
                      <button
                        type="button"
                        className="flex justify-center items-center w-[46px] h-[46px] rounded-full text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Notificações</span>
                        <Bell
                          color={homepage ? colors.white : colors.graydark}
                          size={Sizes.medium}
                        />
                      </button>
                    </DropdownMenu.DropdownMenuTrigger>
                    {notifications && notifications.length > 0 ? (
                      <DropdownMenu.Content sideOffset={5}>
                        <div
                          className="absolute -right-3 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="user-menu-button"
                          tabIndex={-1}
                        >
                          {notifications.map((data, index) => (
                            <>
                              <a
                                key={data.id}
                                href="#"
                                className="block px-4 py-3 text-sm text-gray-700"
                                role="menuitem"
                                tabIndex={-1}
                                id="user-menu-item-0"
                              >
                                {data.content}
                              </a>
                              {notifications.length !== index + 1 && <hr className="my-0" />}
                            </>
                          ))}
                        </div>
                      </DropdownMenu.Content>
                    ) : (
                      <DropdownMenu.Content sideOffset={5}>
                        <div
                          className="absolute -right-3 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="user-menu-button"
                          tabIndex={-1}
                        >
                          <a
                            href="#"
                            className="block px-4 py-3 text-sm text-gray-700"
                            role="menuitem"
                            tabIndex={-1}
                            id="user-menu-item-0"
                          >
                            Você não possui notificações
                          </a>
                        </div>
                      </DropdownMenu.Content>
                    )}
                  </DropdownMenu.Root>
                </li>
                <li className="relative hidden sm:block">
                  <Dropdown
                    trigger={
                      <button
                        type="button"
                        className="flex justify-center items-center w-[46px] h-[46px] rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Perfil</span>
                        <span className="leading-4 font-bold">{getLettersName(user.name)}</span>
                        <span className="flex h-4 items-end">
                          <CaretDown color={colors.white} size={Sizes.nano} />
                        </span>
                      </button>
                    }
                  >
                    <div
                      className="absolute -right-5 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex={-1}
                    >
                      <a
                        href="#"
                        className="flex items-center px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={() => goTo('/profile')}
                      >
                        <img src={User} alt="Meu perfil" />
                        <span className="ml-3 text-sm font-normal font-dmsans">Meu perfil</span>
                      </a>{' '}
                      <a
                        href="#"
                        className="flex items-center px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={() => goTo('/ingressos/pedidos')}
                      >
                        <img src={Ticket} alt="Meus Ingressos" />
                        <span className="ml-3 text-sm font-normal font-dmsans">Meus Pedidos</span>
                      </a>
                      <hr className="my-2" />
                      <a
                        href="#"
                        className="flex items-center px-4 pt-2 pb-3  text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={handleOnLogout}
                      >
                        <img src={Logout} alt="Sair" />
                        <span className="ml-3 text-sm font-normal font-dmsans">Sair</span>
                      </a>
                    </div>
                  </Dropdown>
                </li>
              </>
            ) : (
              <>
                <li className="hidden md:block">
                  <a
                    href="#"
                    className={classNames(
                      homepage ? 'text-white' : ' text-neutro-b-400',
                      'font-dmsans font-normal',
                    )}
                    onClick={handleOnSignUp}
                  >
                    Criar conta
                  </a>
                </li>
                <li className="hidden md:block">
                  <button
                    id="btnDoLogin"
                    className={classNames(
                      'px-10 py-2 transition-colors duration-150 rounded-lg focus:shadow-outline bg-neutro-b-400 hover:bg-neutro-b-600 active:bg-neutro-b-400 text-base font-bold font-dmsans text-white',
                    )}
                    onClick={handleOnSignIn}
                  >
                    Entrar
                  </button>
                </li>
              </>
            )}
            <li className="flex items-center sm:hidden">
              <button
                type="button"
                className={classNames(
                  homepage ? 'bg-white text-heading' : 'bg-[#222222] text-white',
                  'flex justify-center items-center w-[46px] h-[46px] rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800',
                )}
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setOpenMenu(true)}
              >
                <span className="sr-only">Open main menu</span>

                <IconHamburger
                  className={classNames(homepage ? 'text-[#222222]' : 'text-white', 'h-[22px]')}
                />
              </button>
            </li>
          </ul>
        </div>
        <Menu
          visible={openMenu}
          onSetVisible={setOpenMenu}
          handleOnSignIn={handleOnSignIn}
          handleOnSignUp={handleOnSignUp}
          handleOnLogout={handleOnLogout}
          goTo={goTo}
        />
      </div>
    </header>
  );
};
