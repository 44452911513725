/* eslint-disable no-nested-ternary */
import React from 'react';
import { ShouldShowModalProps, ShowModal } from '../types';

interface ProductIncrementProps {
  index: number;
  name: string;
  value: number;
  fee: number;
  count: number;
  quantity: number;
  hasMap: boolean;
  eventKey?: string;
  startDate?: Date;
  endDate?: Date;
  renameTable: boolean;
  groupName?: string;
  increment(index: number): void;
  decrement(index: number): void;
  showModal: (props: ShouldShowModalProps) => void;
}

export const ProductIncrement: React.FC<ProductIncrementProps> = ({
  index,
  name,
  value,
  fee,
  count,
  quantity,
  hasMap,
  eventKey,
  startDate,
  endDate,
  renameTable,
  groupName,
  increment,
  decrement,
  showModal,
}) => {
  const currentDate = new Date();

  let startDateFormat;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  startDate ? (startDateFormat = new Date(startDate)) : null;

  let endDateFormat;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  endDate ? (endDateFormat = new Date(endDate)) : null;

  const toCurrency = (productValue: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(productValue);
  };

  const mountText = (productValue: number, productFee: number): string => {
    let s = '';
    if (productFee && productFee > 0) {
      s = ` + Taxa ${toCurrency(productValue * productFee - productValue)}`;
    }
    return s;
  };

  return (
    <div className="flex justify-between items-center border border-[#D9D9D9] rounded-[5px] py-[18px] px-[25px]">
      <div className="space-y-1">
        <div
          className="font-dmsans font-normal text-base
                          leading-[21px] text-label"
        >
          {renameTable && hasMap
            ? renameTable && hasMap && groupName
              ? groupName
              : 'Escolha a Mesa'
            : name}
        </div>
        <div className="font-dmsans text-xs text-heading">
          {renameTable && hasMap ? '' : `${toCurrency(value)}${mountText(value, fee)}`}
        </div>
      </div>
      <div className="flex justify-center items-center">
        {hasMap ? (
          <button
            className="font-bold border-alert-info-soft border-[#60B9CD] rounded-[5px] w-[90px] h-[18px] flex justify-center items-center text-sm leading-[18px] text-white bg-[#60B9CD]"
            onClick={(): void => showModal({ value: ShowModal.MAP, newTitle: 'Mapa', eventKey })}
          >
            Selecionar
          </button>
        ) : count > 0 ? (
          <>
            <button
              onClick={() => decrement(index)}
              disabled={quantity === 0}
              className="disabled:border-[#ECECEC] disabled:text-[#ECECEC] disabled:cursor-not-allowed border border-[#C4C4C4] rounded-[5px] w-[18px] h-[18px] flex justify-center items-center text-sm leading-[18px] text-placeholder"
            >
              -
            </button>
            <div className="font-dmsans text-base text-heading px-[15px]">{quantity}</div>
            <button
              onClick={() => increment(index)}
              disabled={quantity === count}
              className="disabled:border-[#ECECEC] disabled:text-[#ECECEC] disabled:cursor-not-allowed border border-[#C4C4C4] rounded-[5px] w-[18px] h-[18px] flex justify-center items-center text-sm leading-[18px] text-placeholder"
            >
              +
            </button>
          </>
        ) : endDateFormat && endDateFormat < currentDate ? (
          <div className="font-dmsans text-xs text-heading"> Finalizado </div>
        ) : startDateFormat && startDateFormat >= currentDate && count <= 0 ? (
          <div className="font-dmsans text-xs text-heading"> Aguardando Liberação </div>
        ) : (
          <div className="font-dmsans text-xs text-heading"> Esgotado </div>
        )}
      </div>
    </div>
  );
};
