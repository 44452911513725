import React, { JSX, useEffect, useState } from 'react';
import cep from 'cep-promise';
import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import { updateMask as updateMaskCash, unmask } from '@/helpers/masks/cash';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import {
  updateMask as updateMaskCPFOrCNPJ,
  updateMask as cpfCnpjMask,
} from '@/helpers/masks/cpfCnpj';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { UseFormControl } from 'src/types';
import { useDialog } from '@/hooks/useDialog';
import { getBoolean, getItem, setItem } from '@/helpers/common/localStorage';
import { REACT_APP_AUTH, REACT_CART } from '@/utils/config';
import { AxiosError, api } from '@/services/api';
import Client from '@/model/Client';
import { useDispatch } from 'react-redux';
import Cart from '@/model/Cart';
import CartEvent from '@/model/CartEvent';
import CartEventTicket from '@/model/CartEventTicket';
import { toast } from 'react-toastify';
import CartProduct from '@/model/CartProduct';
import CartCombo from '@/model/CartCombo';
import PaymentType from '@/model/PaymentType';
import { useNavigate } from 'react-router-dom';
import CartPayment from '@/model/CartPayment';
import {
  getCreditCardLabel,
  updateHolderName,
  updateMaskCard,
  updateMaskCVV,
  validateCVV,
} from '@/helpers/masks/cardNumber';
import { updateMask, updateMask as expirationDateMask } from '@/helpers/masks/expirationDate';
import { onlyNumbers } from '@/helpers/common';
import Payment from '@/model/Payment';
import CardFees from '@/model/CardFees';
import {
  EventDate,
  EventDateSectionSite,
  EventGroup,
  EventProductSite,
  EventTicketSite,
} from '@/features/event/screens/Event';
import OrderResponse from '@/model/OrderResponse';
import { updateMask as cepMask } from '@/helpers/masks/cep';
import { updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import { setLoading } from '@/redux/loading/loadingSlice';
import { ControllerCreditCard, FormInputNameCreditCard } from '@/features/profile/types';
import CardClient from '@/model/CardClient';
import CardClientCrypt from '@/model/CardClientCrypt';
import Card from '@/model/Card';
import { decrypt, encrypt } from '@/helpers/crypt/crypt';
import { Address } from '@/model/Address';
import CardDb from '@/model/CardDb';
// import ClientPersonalData from '@/model/ClientPersonalData';
import dayjs from 'dayjs';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import { CardValidationResponse } from '@/model/CardValidationResponse';
import CardValidationResponseStatus from '@/model/CardValidationResponseStatus';
import { SessionResponse } from '@/model/SessionResponse';
import { Authenticate3DSRequest } from '@/model/Authenticate3DSRequest';
import { Authenticate3DSError } from '@/model/Authenticate3DSError';
import { ValidateCardcheckout } from '@/model/ValidateCardcheckout';
import CartStorage from '@/features/core/cart/components/Cart';
import { AxiosResponse } from 'axios';
import MetaPixel from '@/utils/meta/metaPixel';
import {
  Auth3DSResponse,
  Auth3DSStatus,
  CardCheckout,
  CreditCardValidationStatus,
  FormInputNameAddress,
  FormInputNameCheckout,
  ShouldShowModalProps,
  ShowModalPayment,
  UseModalControl,
} from '../../types';
import { CheckoutContainer } from './ui';
import { Loading3DSValidation } from '../../components/Loading3DSValidation';

interface CustomError {
  message: string;
  details: string[];
}

interface StateProps {
  cameFromAdm: boolean;
}
interface DispatchProps {
  sendOrderToAPIEcommerce: (order: Cart) => Promise<AxiosResponse<OrderResponse[]>>;
  sendOrderToAPIAdm: (payments: CartPayment[]) => Promise<AxiosResponse<OrderResponse[]>>;
}

type Props = StateProps & DispatchProps;

export const CheckoutMain: React.FC<Props> = ({
  cameFromAdm,
  sendOrderToAPIEcommerce,
  sendOrderToAPIAdm,
}): JSX.Element => {
  const [element, setElement] = useState<HTMLElement | null>();
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [cart, setCart] = useState<CartStorage>({
    events: [],
    discountCoupon: undefined,
    voucher: undefined,
    amountItens: 0,
    totalValue: 0,
    totalValueWithFee: 0,
    originalValueWithPaymentFee: 0,
    totalValueWithPaymentFee: 0,
    pdvId: undefined,
  });
  const [isWithin24Hours, setIsWithin24Hours] = useState<boolean>(false);
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalPayment>(
    ShowModalPayment.CREDIT_CARD_PAYMENT,
  );
  const [loadedUserData, setLoadedUserData] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<PaymentType>(undefined as unknown as PaymentType);
  const [cardsCheckout, setCardsCheckout] = useState<CardCheckout[]>([]);
  const [payment, setPayment] = useState<Payment>(undefined as unknown as Payment);
  const [orderResponse, setOrderResponse] = useState<OrderResponse[]>([]);
  const [messageError, setMessageError] = useState<string[]>([
    'Houve um erro ao processar o seu cartão. Entre em contato com o seu banco para obter mais informações.',
  ]);
  const [messagePayment, setMessagePayment] = useState<string>();

  // const { cart } = useSelector(useCart);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [client, setClient] = useState<Client>({} as Client);
  const [cardId, setCardId] = useState<string>(undefined as unknown as string);
  const [cardSelected, setCardSelected] = useState<CardDb>();
  const [signed, setSigned] = useState<boolean>(getBoolean(String(REACT_APP_AUTH)));
  const [showLoadingPage3DSValidation, setShowLoadingPage3DSValidation] = useState<boolean>(false);
  const [auth3DSResponses, setAuth3DSResponses] = useState<Auth3DSResponse[]>([]);
  const [auth3DSStatus, setAuth3DSStatus] = useState<Auth3DSStatus>(Auth3DSStatus.NONE);

  const [validateStatus, setValidateStatus] = useState<CreditCardValidationStatus>(
    CreditCardValidationStatus.NONE,
  );
  const [validatingCreditCard, setValidatingCreditCard] = useState<CardClient[]>([]);
  const [cartPayments, setCartPayments] = useState<CartPayment[]>([]);
  const [enableSendOrder, setEnableSendOrder] = useState<boolean>(false);
  const [cardValidationResponses, setCardValidationResponses] = useState<CardValidationResponse[]>(
    [],
  );

  const loadCart = (): void => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: CartStorage | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as CartStorage;
      setCart(cartVariable);
    }
  };

  const checkPageLoaded = (): void => {
    const interval = setInterval(() => {
      const e = document.getElementById('btnDoLogin');
      if (e && interval) {
        setElement(e);
        clearInterval(interval);
      }
    }, 100);
  };

  const getCart = (): CartStorage => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: CartStorage | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as CartStorage;
    } else {
      cartVariable = {
        events: [],
        discountCoupon: undefined,
        voucher: undefined,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as CartStorage;

    return newCart;
  };

  const {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  } = useForm({
    initialData: {
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      number: '',
      hasAddress: '',
    },
    validators: {
      zipCode: [validators.required, validators.maxLength(9)],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      complement: [],
      number: [validators.required],
      hasAddress: [],
    },
    formatters: {
      zipCode: cepMask,
    },
  });

  const {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  } = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      phone: '',
      birthDate: '',
      motherName: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      birthDate: [validators.required, validators.birthday],
      motherName: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      birthDate: updateMaskDate,
    },
  });

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
      } else {
        isAuth();
      }
    }, 500);
  };

  const getUserData = async (): Promise<void> => {
    const storageCart = getCart();

    if (storageCart && storageCart.events && storageCart.events.length > 0) {
      if (signed) {
        try {
          let showUserData = false;
          storageCart.events.forEach(event => {
            if (event.startDate && event.startDate !== undefined) {
              const eventDate = new Date(event.startDate as Date);
              const dateMilissegundos = eventDate.getTime() - new Date().getTime();
              const hoursDifference = dateMilissegundos / (1000 * 60 * 60);

              if (hoursDifference <= 24) {
                showUserData = true;
              }
            }
          });
          setIsWithin24Hours(showUserData);
          const response = await api.get<Client>('client/my-account');
          if (showUserData) {
            // eslint-disable-next-line no-plusplus
            for (let e = 0; e < storageCart.events.length; e++) {
              if (storageCart.events[e].sections && storageCart.events[e].sections.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let s = 0; s < storageCart.events[e].sections.length; s++) {
                  if (
                    storageCart.events[e].sections[s].tickets &&
                    storageCart.events[e].sections[s].tickets.length > 0
                  ) {
                    // eslint-disable-next-line no-plusplus
                    for (let t = 0; t < storageCart.events[e].sections[s].tickets.length; t++) {
                      storageCart.events[e].sections[s].tickets[t].userDatas = [];
                      for (
                        let q = 0;
                        q < storageCart.events[e].sections[s].tickets[t].quantity;
                        // eslint-disable-next-line no-plusplus
                        q++
                      ) {
                        const newUserData = {} as CartEventTicketUserData;
                        if (q === 0) {
                          newUserData.name = response.data.name;
                          newUserData.document = updateMaskCPF(response.data.cpf);
                        }
                        storageCart.events[e].sections[s].tickets[t].userDatas.push(newUserData);
                      }
                    }
                  }
                }
              }
            }
            let countTickets = 0;
            let countTicketsWithUserData = 0;
            if (storageCart.events && storageCart.events.length > 0) {
              storageCart.events.forEach(event => {
                if (event.sections && event.sections.length > 0) {
                  event.sections.forEach(section => {
                    if (section.tickets && section.tickets.length > 0) {
                      section.tickets.forEach(ticket => {
                        countTickets += Number(ticket.quantity);
                        if (ticket.userDatas && ticket.userDatas.length > 0) {
                          ticket.userDatas.forEach(userData => {
                            if (
                              userData.document &&
                              userData.document.trim().length > 0 &&
                              onlyNumbers(userData.document).length === 11
                            ) {
                              countTicketsWithUserData += 1;
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
            if (countTickets === countTicketsWithUserData) {
              setEnableSendOrder(true);
            }
          } else {
            setEnableSendOrder(true);
          }
          setItem(REACT_CART as string, JSON.stringify(storageCart));
          setCart(storageCart);

          setClient(response.data);

          if (response.data.address) {
            const address = response.data.address as Address;
            onChangeFormInputAddress(FormInputNameAddress.id)(address.id);
            onChangeFormInputAddress(FormInputNameAddress.zipCode)(address.zipCode);
            onChangeFormInputAddress(FormInputNameAddress.state)(address.state);
            onChangeFormInputAddress(FormInputNameAddress.city)(address.city);
            onChangeFormInputAddress(FormInputNameAddress.district)(address.district);
            onChangeFormInputAddress(FormInputNameAddress.street)(address.street);
            onChangeFormInputAddress(FormInputNameAddress.number)(address.number);
            onChangeFormInputAddress(FormInputNameAddress.complement)(address.complement);
          }

          onChangeFormInputPersonalInfo(FormInputNameCheckout.name)(response.data.name);
          onChangeFormInputPersonalInfo(FormInputNameCheckout.document)(
            response.data.cpf ? response.data.cpf : '',
          );
          onChangeFormInputPersonalInfo(FormInputNameCheckout.email)(
            response.data.email ? response.data.email : '',
          );
          onChangeFormInputPersonalInfo(FormInputNameCheckout.phone)(
            response.data.cellPhone ? response.data.cellPhone : '',
          );
          onChangeFormInputPersonalInfo(FormInputNameCheckout.birthDate)(
            response.data.birthDate ? dayjs(response.data.birthDate).format('DD/MM/YYYY') : '',
          );
          onChangeFormInputPersonalInfo(FormInputNameCheckout.motherName)(
            response.data.motherName ? response.data.motherName : '',
          );
          setLoadedUserData(true);
        } catch {
          isAuth();
        }
      } else {
        if (element) {
          element.click();
        }
        isAuth();
      }
    } else {
      history('/');
    }
  };

  const onChangeTransferDocument = (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ): void => {
    if (onlyNumbers(value).trim().length < 11 && enableSendOrder) {
      setEnableSendOrder(false);
    }
    const newCart = getCart();
    if (newCart && newCart.events && newCart.events.length > 0) {
      let found = false;
      // eslint-disable-next-line no-plusplus
      for (let e = 0; e < newCart.events.length; e++) {
        if (
          newCart.events[e].id === eventId &&
          newCart.events[e].sections &&
          newCart.events[e].sections.length > 0
        ) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < newCart.events[e].sections.length; s++) {
            if (
              newCart.events[e].sections[s].section.id === sectionId &&
              newCart.events[e].sections[s].tickets &&
              newCart.events[e].sections[s].tickets.length > 0
            ) {
              // eslint-disable-next-line no-plusplus
              for (let t = 0; t < newCart.events[e].sections[s].tickets.length; t++) {
                if (
                  newCart.events[e].sections[s].tickets[t].id === ticketId &&
                  newCart.events[e].sections[s].tickets[t].userDatas &&
                  newCart.events[e].sections[s].tickets[t].userDatas.length > 0
                ) {
                  for (
                    let u = 0;
                    u < newCart.events[e].sections[s].tickets[t].userDatas.length;
                    // eslint-disable-next-line no-plusplus
                    u++
                  ) {
                    if (u === index) {
                      found = true;
                      newCart.events[e].sections[s].tickets[t].userDatas[u].document =
                        updateMaskCPF(value);
                      break;
                    }
                  }
                  if (found) {
                    break;
                  }
                }
              }
            }
            if (found) {
              break;
            }
          }
        }
        if (found) {
          break;
        }
      }
    }
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const handleOnCheckCpfTransfer = async (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ): Promise<void> => {
    const newCart = getCart();
    if (newCart && newCart.events && newCart.events.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let e = 0; e < newCart.events.length; e++) {
        if (
          newCart.events[e].id === eventId &&
          newCart.events[e].sections &&
          newCart.events[e].sections.length > 0
        ) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < newCart.events[e].sections.length; s++) {
            if (
              newCart.events[e].sections[s].section.id === sectionId &&
              newCart.events[e].sections[s].tickets &&
              newCart.events[e].sections[s].tickets.length > 0
            ) {
              // eslint-disable-next-line no-plusplus
              for (let t = 0; t < newCart.events[e].sections[s].tickets.length; t++) {
                if (
                  newCart.events[e].sections[s].tickets[t].id === ticketId &&
                  newCart.events[e].sections[s].tickets[t].userDatas &&
                  newCart.events[e].sections[s].tickets[t].userDatas.length > 0
                ) {
                  for (
                    let u = 0;
                    u < newCart.events[e].sections[s].tickets[t].userDatas.length;
                    // eslint-disable-next-line no-plusplus
                    u++
                  ) {
                    if (u === index) {
                      if (value) {
                        try {
                          dispatch(setLoading(true));
                          // eslint-disable-next-line no-await-in-loop
                          const { data } = await api.get<CartEventTicketUserData>(
                            `/client/cpf/${value}/check`,
                          );
                          if (!data.name) {
                            newCart.events[e].sections[s].tickets[t].userDatas[u].document = '';
                            newCart.events[e].sections[s].tickets[t].userDatas[u].documentError =
                              'CPF informado não possui cadastro!';
                          } else {
                            newCart.events[e].sections[s].tickets[t].userDatas[u].documentError =
                              '';
                          }
                        } catch (error) {
                          const err = error as AxiosError;
                          toast.error(err.message);
                        } finally {
                          dispatch(setLoading(false));
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    let countTickets = 0;
    let countTicketsWithUserData = 0;
    if (newCart.events && newCart.events.length > 0) {
      newCart.events.forEach(event => {
        if (event.sections && event.sections.length > 0) {
          event.sections.forEach(section => {
            if (section.tickets && section.tickets.length > 0) {
              section.tickets.forEach(ticket => {
                countTickets += Number(ticket.quantity);
                if (ticket.userDatas && ticket.userDatas.length > 0) {
                  ticket.userDatas.forEach(userData => {
                    if (
                      userData.document &&
                      userData.document.trim().length > 0 &&
                      onlyNumbers(userData.document).length === 11
                    ) {
                      countTicketsWithUserData += 1;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

    if (countTickets === countTicketsWithUserData) {
      setEnableSendOrder(true);
    } else {
      setEnableSendOrder(false);
    }
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const showModal = ({ value, newTitle }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitle);
    onSetVisible(true);
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const handleSetPaymentType = (type: PaymentType): void => {
    setCardsCheckout([]);
    setCardSelected(undefined);
    if (type !== undefined && type !== null) {
      let fee = 0;
      if (type === PaymentType.CREDIT_CARD) {
        const list = [];
        list.push({
          value: toCurrency(cart.originalValueWithPaymentFee),
          valueWithFee: cart.originalValueWithPaymentFee,
          installments: 0,
          cardSelectedInt: 50,
        } as CardCheckout);
        setCardsCheckout(list);
      } else if (type === PaymentType.TWO_CREDIT_CARDS) {
        const list = [];
        list.push({
          value: toCurrency(cart.originalValueWithPaymentFee / 2),
          valueWithFee: cart.originalValueWithPaymentFee / 2,
          installments: 0,
          cardSelectedInt: 50,
        } as CardCheckout);
        list.push({
          value: toCurrency(cart.originalValueWithPaymentFee / 2),
          valueWithFee: cart.originalValueWithPaymentFee / 2,
        } as CardCheckout);
        setCardsCheckout(list);
      } else if (type === PaymentType.BANK_SLIP) {
        fee = payment.fees.bankSlip;
      } else if (type === PaymentType.PIX) {
        fee = payment.fees.pix;
      }
      fee = fee / 100 + 1;
      const totalValueWithPaymentFee = cart.originalValueWithPaymentFee * fee;

      const newEventDates: EventDate[] = [];
      const newSections: EventDateSectionSite[] = [];
      const newGroups: EventGroup[] = [];
      cart.events.forEach(event => {
        if (event.sections && event.sections.length > 0) {
          event.sections.forEach(section => {
            const newTickets: EventTicketSite[] = [];
            if (section.tickets && section.tickets.length > 0) {
              section.tickets.forEach(ticket => {
                const newTicket = {
                  ...ticket,
                  fee,
                  totalValue: Number(ticket.unitValue) * fee,
                };
                newTickets.push(newTicket);
              });
            }
            const newSection = {
              ...section,
              tickets: newTickets,
            };
            newSections.push(newSection);
          });
        }
        if (event.groups && event.groups.length > 0) {
          const newProducts: EventProductSite[] = [];
          const newCombos: EventProductSite[] = [];
          event.groups.forEach(group => {
            if (group.products && group.products.length > 0) {
              group.products.forEach(product => {
                const newProduct = {
                  ...product,
                  fee,
                  totalValue: Number(product.unitValue) * fee,
                };
                newProducts.push(newProduct);
              });
            }
            if (group.combos && group.combos.length > 0) {
              group.combos.forEach(combo => {
                const newCombo = {
                  ...combo,
                  fee,
                  totalValue: Number(combo.unitValue) * fee,
                };
                newCombos.push(newCombo);
              });
            }
            const newGroup = {
              ...group,
              products: newProducts,
              combos: newCombos,
            };
            newGroups.push(newGroup);
          });
        }
        const newEvent = {
          ...event,
          sections: newSections,
          groups: newGroups,
        };
        newEventDates.push(newEvent);
      });

      const newCart = {
        ...cart,

        totalValueWithPaymentFee,
      };

      // dispatch(setCart(newCart));
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
    }

    setPaymentType(type);
  };

  const handleCardSelectedInt = (indexList: number, index: number): void => {
    let newlist: CardCheckout[] = [];
    newlist = newlist.concat(cardsCheckout);
    newlist[indexList].cardSelectedInt = index;
    setCardsCheckout(newlist);
  };

  const changeAmountItens = (): void => {
    handleSetPaymentType(undefined as unknown as PaymentType);
    cardsCheckout.forEach((_data, index) => {
      handleCardSelectedInt(index, 50);
    });
  };

  const incrementTicket = (item: EventTicketSite): void => {
    const newCart = getCart();
    newCart.events.forEach(event => {
      event.sections.forEach(section => {
        section.tickets.forEach(ticket => {
          if (ticket.id === item.id && ticket.isHalfPrice === item.isHalfPrice) {
            ticket.userDatas.push({} as CartEventTicketUserData);
            // eslint-disable-next-line no-param-reassign
            ticket.quantity += 1;
            newCart.amountItens += 1;
            newCart.totalValue = Number(newCart.totalValue) + Number(ticket.unitValue);
            newCart.totalValueWithFee =
              Number(newCart.totalValueWithFee) + Number(ticket.unitValue) * Number(ticket.fee);
            newCart.totalValueWithPaymentFee =
              Number(newCart.totalValueWithPaymentFee) +
              Number(ticket.unitValue) * Number(ticket.fee);
            newCart.originalValueWithPaymentFee =
              Number(newCart.originalValueWithPaymentFee) +
              Number(ticket.unitValue) * Number(ticket.fee);
          }
        });
      });
    });
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    changeAmountItens();
  };

  const handleClear = (): void => {
    const newCart = {
      events: [],
      discountCoupon: undefined,
      voucher: undefined,
      amountItens: 0,
      totalValue: 0,
      totalValueWithFee: 0,
      originalValueWithPaymentFee: 0,
      totalValueWithPaymentFee: 0,
    };
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    history('/');
  };

  const removeCupom = (): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    if (cart.discountCoupon) {
      newCart.totalValueWithPaymentFee = newCart.totalValueWithFee;
    } else if (cart.voucher) {
      newCart.totalValueWithPaymentFee = newCart.totalValueWithFee;
    }
    newCart.discountCoupon = undefined;
    newCart.voucher = undefined;
    // dispatch(setCart(newCart));
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    changeAmountItens();
  };

  const decrementTicket = (item: EventTicketSite): void => {
    const newCart = getCart();
    let countTicketDiscount = 0;

    let spliceEvent = false;
    let countEvent = 0;
    let indexEvent = 0;
    newCart.events.forEach(data => {
      let spliceSection = false;
      let countSection = 0;
      let indexSection = 0;
      data.sections.forEach(section => {
        let spliceTicket = false;
        let countTicket = 0;
        let indexTicket = 0;
        section.tickets.forEach(ticket => {
          if (
            newCart.checkDiscount &&
            newCart.checkDiscount.tickets &&
            newCart.checkDiscount.tickets.length > 0 &&
            newCart.discountCoupon
          ) {
            // eslint-disable-next-line no-plusplus
            for (let td = 0; td < newCart.checkDiscount.tickets.length; td++) {
              if (
                newCart.checkDiscount.tickets[td].discountCoupon &&
                newCart.checkDiscount.tickets[td].discountCoupon?.id === newCart.discountCoupon.id
              ) {
                if (ticket.id === newCart.checkDiscount.tickets[td].id) {
                  countTicketDiscount = ticket.quantity - 1;
                  break;
                }
              }
            }
          }
          if (ticket.id === item.id && ticket.unitValue === item.unitValue) {
            ticket.userDatas.splice(ticket.userDatas.length - 1, 1);
            // eslint-disable-next-line no-param-reassign
            ticket.quantity -= 1;
            if (ticket.quantity === 0) {
              spliceTicket = true;
              indexTicket = countTicket + 0;
            }
            newCart.amountItens -= 1;
            newCart.totalValue = Number(newCart.totalValue) - Number(item.unitValue);
            newCart.totalValueWithFee =
              Number(newCart.totalValueWithFee) - Number(ticket.unitValue) * Number(ticket.fee);
            newCart.totalValueWithPaymentFee =
              Number(newCart.totalValueWithPaymentFee) -
              Number(ticket.unitValue) * Number(ticket.fee);
            newCart.originalValueWithPaymentFee =
              Number(newCart.originalValueWithPaymentFee) -
              Number(ticket.unitValue) * Number(ticket.fee);
          }
          // eslint-disable-next-line no-plusplus
          countTicket++;
        });
        if (spliceTicket) {
          section.tickets.splice(indexTicket, 1);
        }
        if (section.tickets.length === 0) {
          spliceSection = true;
          indexSection = countSection + 0;
        }
        // eslint-disable-next-line no-plusplus
        countSection++;
      });
      if (spliceSection) {
        data.sections.splice(indexSection, 1);
      }

      if (data.sections.length === 0) {
        spliceEvent = true;
        indexEvent = countEvent + 0;
      }
      // eslint-disable-next-line no-plusplus
      countEvent++;
    });

    if (spliceEvent) {
      newCart.events.splice(indexEvent, 1);
    }

    if (newCart.amountItens > 0) {
      // dispatch(setCart(newCart));
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
      changeAmountItens();
      if (
        countTicketDiscount === 0 &&
        newCart.checkDiscount &&
        newCart.checkDiscount.tickets &&
        newCart.checkDiscount.tickets.length > 0 &&
        newCart.discountCoupon
      ) {
        newCart.checkDiscount = undefined;
        newCart.discountCoupon = undefined;
        removeCupom();
      }
    } else {
      handleClear();
    }
  };

  const incrementProduct = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.products[index].quantity += 1;
              newCart.amountItens += 1;
              newCart.totalValue =
                Number(newCart.totalValue) + Number(group.products[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.originalValueWithPaymentFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
            }
          });
        }
      }
    });
    // dispatch(setCart(newCart));
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    changeAmountItens();
  };

  const decrementProduct = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          let splice = false;
          let count = 0;
          let indexProduct = 0;
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.products[index].quantity -= 1;
              if (group.products[index].quantity === 0) {
                splice = true;
                indexProduct = count + 0;
              }
              newCart.amountItens -= 1;
              newCart.totalValue =
                Number(newCart.totalValue) - Number(group.products[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.originalValueWithPaymentFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
            }
            // eslint-disable-next-line no-plusplus
            count++;
          });
          if (splice) {
            event.groups[indexProduct].products.splice(index, 1);
          }
          if (
            event.groups[indexProduct].products.length === 0 &&
            event.groups[indexProduct].combos.length === 0
          ) {
            event.groups.splice(indexProduct, 1);
          }
        }
      }
    });
    if (newCart.amountItens > 0) {
      // dispatch(setCart(newCart));
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
      changeAmountItens();
    } else {
      handleClear();
    }
  };

  const incrementCombo = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.combos[index].quantity += 1;
              newCart.amountItens += 1;
              newCart.totalValue =
                Number(newCart.totalValue) + Number(group.combos[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.originalValueWithPaymentFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
            }
          });
        }
      }
    });
    // dispatch(setCart(newCart));
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    changeAmountItens();
  };

  const decrementCombo = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          let splice = false;
          let count = 0;
          let indexCombo = 0;
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.combos[index].quantity -= 1;
              if (group.combos[index].quantity === 0) {
                splice = true;
                indexCombo = count + 0;
              }
              newCart.amountItens -= 1;
              newCart.totalValue =
                Number(newCart.totalValue) - Number(group.combos[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.originalValueWithPaymentFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
            }
            // eslint-disable-next-line no-plusplus
            count++;
          });
          if (splice) {
            event.groups[indexCombo].combos.splice(index, 1);
          }
          if (
            event.groups[indexCombo].products.length === 0 &&
            event.groups[indexCombo].combos.length === 0
          ) {
            event.groups.splice(indexCombo, 1);
          }
        }
      }
    });
    if (newCart.amountItens > 0) {
      // dispatch(setCart(newCart));
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
      changeAmountItens();
    } else {
      handleClear();
    }
  };

  const addMask = (name: string, value: string): string => {
    let s = '';
    switch (name) {
      case 'number':
        s = updateMaskCard(value);
        break;
      case 'expDate':
        s = updateMask(value);
        break;
      case 'securityCode':
        s = updateMaskCVV(value);
        break;
      case 'document':
        s = updateMaskCPFOrCNPJ(value);
        break;
      case 'value':
        s = updateMaskCash(value);
        break;
      case 'zipCode':
        s = cepMask(value);
        break;
      case 'validationValue':
        s = updateMaskCash(value);
        break;
      default:
        s = value;
        break;
    }
    return s;
  };

  const handleChangeCreditCard = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const list: CardCheckout[] = [];
    let totalValue = 0;
    cardsCheckout.forEach((data, i) => {
      if (e.target.name === 'installments') {
        let value = data.valueWithFee ? data.valueWithFee : 0;
        const feeInstallment = payment.fees.fee / 100;
        const fee = payment.fees.credit / 100;
        value += value * fee;
        const installments =
          // eslint-disable-next-line no-nested-ternary
          i === index ? Number(e.target.value) : data.installments ? data.installments : 0;
        if (installments === 1) {
          value += 0;
        } else {
          value *= (1 + feeInstallment) ** (installments - 1);
        }

        /*

        value = data.valueWithFee ? data.valueWithFee : 0;
        value = installments > 0 ? value * fee : value;

        for (let i = 1; i <= installments; i++) {
          value *= feeInstallment;
        }
        */
        totalValue += value;
        // newCardCheckout.valueWithFee = value;
      }
      if (i === index) {
        const newCardCheckout: CardCheckout = {
          ...data,
          [e.target.name]: addMask(e.target.name, e.target.value),
        };
        if (e.target.name === 'value') {
          newCardCheckout.installments = 0;
          newCardCheckout.valueWithFee = Number(unmask(addMask('value', e.target.value)));
        }

        list.push(newCardCheckout);
      } else if (e.target.name === 'value') {
        let otherValue = 0;
        if (cardsCheckout.length > 1) {
          cardsCheckout.forEach(value => {
            otherValue += value.valueWithFee;
          });
        }
        otherValue -= Number(unmask(addMask('value', e.target.value)));
        const newCardCheckout: CardCheckout = {
          ...data,
          value: toCurrency(otherValue),
          valueWithFee: otherValue,
        };
        list.push(newCardCheckout);
      } else {
        list.push(data);
      }
    });
    setCardsCheckout(list);

    if (e.target.name === 'installments') {
      const newCart = {
        ...cart,
        totalValueWithPaymentFee: totalValue,
      };
      // dispatch(setCart(newCart));
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
    }
  };

  const checkPaymentOptions = (): void => {
    const tickets: EventTicketSite[] = [];
    const list: Payment[] = [];
    const listCardFees: CardFees[] = [];
    if (cart.events && cart.events.length > 0) {
      cart.events.forEach(event => {
        if (event.sections && event.sections.length > 0) {
          event.sections.forEach(section => {
            if (section.tickets && section.tickets.length > 0) {
              section.tickets.forEach(ticket => {
                tickets.push(ticket);
              });
            }
          });
        }
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            if (group.products && group.products.length > 0) {
              group.products.forEach(product => {
                const newPayment: Payment = {
                  installmentLimit: product.fees.installments,
                  allowFractionalPayment: product.partialPayment,
                  allowPaymentBankSlip: !!(product.fees.bankSlip && product.fees.bankSlip > 0),
                  allowPaymentPIX: !!(product.fees.pix && product.fees.pix > 0),
                  allowContactlessPayment: product.fees.allowCreditCardPayment,
                  fees: product.fees,
                  allowDiscount: product.allowDiscountCoupon,
                };
                list.push(newPayment);
                listCardFees.push(product.fees);
              });
            }

            if (group.combos && group.combos.length > 0) {
              group.combos.forEach(combo => {
                const newPayment: Payment = {
                  installmentLimit: combo.fees.installments,
                  allowFractionalPayment: combo.partialPayment,
                  allowPaymentBankSlip: !!(combo.fees.bankSlip && combo.fees.bankSlip > 0),
                  allowPaymentPIX: !!(combo.fees.pix && combo.fees.pix > 0),
                  allowContactlessPayment: combo.fees.allowCreditCardPayment,
                  fees: combo.fees,
                  allowDiscount: combo.allowDiscountCoupon,
                };
                list.push(newPayment);
                listCardFees.push(combo.fees);
              });
            }
          });
        }
      });
    }

    if (tickets && tickets.length > 0) {
      tickets.forEach(ticket => {
        if (ticket.payment) {
          list.push(ticket.payment);
          if (ticket.payment.fees) {
            if (ticket.payment.fees) {
              listCardFees.push(ticket.payment.fees);
            }
          }
        }
      });
    }

    const installmentLimitList = list.sort((a, b) => {
      if (a.installmentLimit < b.installmentLimit) {
        return -1;
      }
      if (a.installmentLimit > b.installmentLimit) {
        return 1;
      }
      return 0;
    });
    const { installmentLimit } =
      installmentLimitList && installmentLimitList.length > 0
        ? installmentLimitList[0]
        : (undefined as unknown as Payment);

    let allowFractionalPayment = true;
    // eslint-disable-next-line no-plusplus
    for (let alp = 0; alp < list.length; alp++) {
      if (!list[alp].allowFractionalPayment) {
        allowFractionalPayment = false;
        break;
      }
    }

    let allowPaymentBankSlip = true;
    // eslint-disable-next-line no-plusplus
    for (let apbs = 0; apbs < list.length; apbs++) {
      if (!list[apbs].allowPaymentBankSlip) {
        allowPaymentBankSlip = false;
        break;
      }
    }

    let allowPaymentPIX = true;
    // eslint-disable-next-line no-plusplus
    for (let app = 0; app < list.length; app++) {
      if (!list[app].allowPaymentPIX) {
        allowPaymentPIX = false;
        break;
      }
    }

    let allowContactlessPayment = true;
    // eslint-disable-next-line no-plusplus
    for (let acp = 0; acp < list.length; acp++) {
      if (!list[acp].allowContactlessPayment) {
        allowContactlessPayment = false;
        break;
      }
    }

    let allowDiscount = false;
    // eslint-disable-next-line no-plusplus
    for (let ad = 0; ad < list.length; ad++) {
      if (list[ad].allowDiscount) {
        allowDiscount = true;
        break;
      }
    }

    let allowCreditCardPayment = true;
    // eslint-disable-next-line no-plusplus
    for (let accp = 0; accp < listCardFees.length; accp++) {
      if (!listCardFees[accp].allowCreditCardPayment) {
        allowCreditCardPayment = false;
        break;
      }
    }
    const debitSorted = listCardFees.sort((a, b) => {
      if (a.debit > b.debit) {
        return -1;
      }
      if (a.debit < b.debit) {
        return 1;
      }
      return 0;
    });

    const creditSorted = listCardFees.sort((a, b) => {
      if (a.credit > b.credit) {
        return -1;
      }
      if (a.credit < b.credit) {
        return 1;
      }
      return 0;
    });

    const bankSlipSorted = listCardFees.sort((a, b) => {
      if (a.bankSlip > b.bankSlip) {
        return -1;
      }
      if (a.bankSlip < b.bankSlip) {
        return 1;
      }
      return 0;
    });

    const pixSorted = listCardFees.sort((a, b) => {
      if (a.pix > b.pix) {
        return -1;
      }
      if (a.pix < b.pix) {
        return 1;
      }
      return 0;
    });

    const installmentsSorted = listCardFees.sort((a, b) => {
      if (a.installments < b.installments) {
        return -1;
      }
      if (a.installments > b.installments) {
        return 1;
      }
      return 0;
    });

    const feeSorted = listCardFees.sort((a, b) => {
      if (a.fee > b.fee) {
        return -1;
      }
      if (a.fee < b.fee) {
        return 1;
      }
      return 0;
    });

    const fees: CardFees = {
      allowCreditCardPayment,
      debit: debitSorted[0].debit,
      credit: creditSorted[0].credit,
      bankSlip: bankSlipSorted[0].bankSlip,
      pix: pixSorted[0].pix,
      installments: installmentsSorted[0].installments,
      fee: feeSorted[0].fee,
    };

    const dto: Payment = {
      installmentLimit,
      allowFractionalPayment,
      allowPaymentBankSlip,
      allowPaymentPIX,
      allowContactlessPayment,
      fees,
      allowDiscount,
    };
    setPayment(dto);
  };

  const isCardCheckoutValid = (index: number): boolean => {
    const newCardCheckout = cardsCheckout[index];
    let b = true;
    let validate: string | boolean;
    validate = newCardCheckout.number
      ? validators.cardNumber(newCardCheckout.number)
      : 'Campo obrigatório';
    newCardCheckout.numberError = validate || (undefined as unknown as string);

    validate = newCardCheckout.expDate
      ? validators.cardExpirationDate(newCardCheckout.expDate)
      : 'Campo obrigatório';
    newCardCheckout.expDateError = validate || (undefined as unknown as string);

    validate = newCardCheckout.securityCode
      ? validateCVV(onlyNumbers(newCardCheckout.number), newCardCheckout.securityCode)
      : 'Campo obrigatório';
    // eslint-disable-next-line no-nested-ternary
    newCardCheckout.securityCodeError = !validate
      ? 'Digito verificador inválido.'
      : validate === 'Campo obrigatório'
        ? validate
        : (undefined as unknown as string);

    if (newCardCheckout.securityCodeDb !== newCardCheckout.securityCode) {
      newCardCheckout.securityCodeError = 'Digito verificador não confere.';
    }

    validate = newCardCheckout.holder ? false : 'Campo obrigatório';
    newCardCheckout.holderError = validate || (undefined as unknown as string);

    validate = newCardCheckout.document
      ? validators.cpforcnpj(newCardCheckout.document)
      : 'Campo obrigatório';
    newCardCheckout.documentError = validate || (undefined as unknown as string);

    if (cardsCheckout.length > 1) {
      if (!newCardCheckout.value) {
        newCardCheckout.valueError = 'Campo obrigatório';
      } else {
        const unMasked = Number(unmask(newCardCheckout.value));
        if (unMasked === 0) {
          newCardCheckout.valueError = 'Valor deverá ser maior que zero';
        } else if (unMasked > cart.totalValueWithFee) {
          newCardCheckout.valueError = 'Valor informado maior que o valor total dos itens';
        } else {
          newCardCheckout.valueError = undefined as unknown as string;
        }
      }
    }

    if (!newCardCheckout.valueError && cardsCheckout.length > 1) {
      let cardValues = 0;
      cardsCheckout.forEach(data => {
        const unMasked = data.value ? Number(unmask(data.value)) : 0;
        cardValues += unMasked;
      });

      if (toCurrency(cardValues) !== toCurrency(cart.totalValueWithFee)) {
        newCardCheckout.valueError = 'Valor total divergente do valor dos cartões';
      }
    }

    newCardCheckout.installmentsError =
      !newCardCheckout.installments || newCardCheckout.installments === 0
        ? 'Campo obrigatório'
        : (undefined as unknown as string);
    if (
      newCardCheckout.numberError ||
      newCardCheckout.expDateError ||
      newCardCheckout.securityCodeError ||
      newCardCheckout.holderError ||
      newCardCheckout.documentError ||
      newCardCheckout.valueError ||
      newCardCheckout.installmentsError
    ) {
      b = false;
    }
    if (!b) {
      const list: CardCheckout[] = [];
      cardsCheckout.forEach((data, i) => {
        if (i === index) {
          list.push(newCardCheckout);
        } else {
          list.push(data);
        }
      });
      setCardsCheckout(list);
    }

    return b;
  };

  const handleOnCheckCpf = async (cpf: string): Promise<void> => {
    onChangeFormInputPersonalInfo(FormInputNameCheckout.name)('');
    onChangeFormInputPersonalInfo(FormInputNameCheckout.email)('');
    onChangeFormInputPersonalInfo(FormInputNameCheckout.phone)('');
    onChangeFormInputPersonalInfo(FormInputNameCheckout.birthDate)('');
    onChangeFormInputPersonalInfo(FormInputNameCheckout.motherName)('');
    try {
      dispatch(setLoading(true));
      const { data } = await api.get<CartEventTicketUserData>(`/client/cpf/${cpf}/check`);
      if (data.name) {
        if (data.name) {
          onChangeFormInputPersonalInfo(FormInputNameCheckout.name)(data.name);
        }
        if (data.email) {
          onChangeFormInputPersonalInfo(FormInputNameCheckout.email)(data.email);
        }
        if (data.phone) {
          onChangeFormInputPersonalInfo(FormInputNameCheckout.phone)(data.phone);
        }
        if (data.birthDate) {
          onChangeFormInputPersonalInfo(FormInputNameCheckout.birthDate)(
            data.birthDate ? dayjs(data.birthDate).format('DD/MM/YYYY') : '',
          );
        }
        if (data.motherName) {
          onChangeFormInputPersonalInfo(FormInputNameCheckout.motherName)(
            data.motherName ? data.motherName : '',
          );
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const savePersonalInfo = async (): Promise<void> => {
    const birthDateSplit = formDataPersonalInfo[FormInputNameCheckout.birthDate].split('/');
    const birthDateString = `${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}T12:00:00.000Z`;
    const clientPersonalData = {
      name: formDataPersonalInfo[FormInputNameCheckout.name],
      cellPhone: formDataPersonalInfo[FormInputNameCheckout.phone],
      email: formDataPersonalInfo[FormInputNameCheckout.email],
      cpf: formDataPersonalInfo[FormInputNameCheckout.document],
      birthDate: new Date(birthDateString),
      motherName: formDataPersonalInfo[FormInputNameCheckout.motherName],
    } as Client;
    await api.patch('client/my-account', clientPersonalData);
  };

  const saveAddress = async (): Promise<void> => {
    const address: Address = {
      id: formDataAddress[FormInputNameAddress.id],
      zipCode: formDataAddress[FormInputNameAddress.zipCode],
      state: formDataAddress[FormInputNameAddress.state],
      city: formDataAddress[FormInputNameAddress.city],
      district: formDataAddress[FormInputNameAddress.district],
      street: formDataAddress[FormInputNameAddress.street],
      complement: formDataAddress[FormInputNameAddress.complement],
      number: formDataAddress[FormInputNameAddress.number],
    };
    await api.patch('client/my-account/address', address);
  };

  const checkPaymentCreditCard = async (): Promise<boolean | string[]> => {
    let b = true;
    let errorMessages: string[] | undefined;
    if (paymentType === PaymentType.CREDIT_CARD || paymentType === PaymentType.TWO_CREDIT_CARDS) {
      const newValidatingCreditCard: CardClient[] = [];
      const newCartPayments: CartPayment[] = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < cardsCheckout.length; index++) {
        const data = cardsCheckout[index];
        if (isCardCheckoutValid(index)) {
          // eslint-disable-next-line no-await-in-loop
          const response = await api.get<CardClientCrypt>(`client/my-account/card/${data.id}`);
          const cardDB = JSON.parse(decrypt(response.data.data)) as CardDb;
          if (!cardDB.validated) {
            const cards = client.cards as CardClient[];
            // eslint-disable-next-line no-plusplus
            for (let c = 0; c < cards.length; c++) {
              if (cards[c].id === data.id) {
                const { REACT_APP_PAGSEGURO_TOKEN } = process.env;
                const expirationDate = cardDB.expirationDate.split('/');
                const cardPagSeguro = PagSeguro.encryptCard({
                  publicKey: REACT_APP_PAGSEGURO_TOKEN,
                  holder: cardDB.cardholderName,
                  number: onlyNumbers(cardDB.number),
                  expMonth: expirationDate[0],
                  expYear: expirationDate[1],
                  securityCode: cardDB.cvv,
                });
                if (cardPagSeguro.errors && cardPagSeguro.errors.length > 0) {
                  errorMessages = [];
                  // eslint-disable-next-line no-loop-func
                  cardPagSeguro.errors.forEach(err => {
                    errorMessages?.push(err.message);
                  });
                  break;
                } else {
                  const encrypted = cardPagSeguro.encryptedCard;
                  const cartPayment: CartPayment = {
                    paymentType: PaymentType.CREDIT_CARD,
                    installments: 1,
                    clientCardId: cardDB.id,
                    encrypted,
                    paymentValue: 0,
                  };
                  newCartPayments.push(cartPayment);
                  newValidatingCreditCard.push(cards[c]);
                }
                break;
              }
            }
          }
          b = cardDB.validated;
        }
      }
      setValidatingCreditCard(newValidatingCreditCard);
      setCartPayments(newCartPayments);
    }
    if (errorMessages) {
      return errorMessages;
    }
    return b;
  };

  const handleOnChangeInputValidate = (index: number, value: string): void => {
    const newCardValidationResponses: CardValidationResponse[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cardValidationResponses.length; i++) {
      if (i === index) {
        newCardValidationResponses.push({
          ...cardValidationResponses[i],
          value: updateMaskCash(value),
        });
      } else {
        newCardValidationResponses.push(cardValidationResponses[i]);
      }
    }
    setCardValidationResponses(newCardValidationResponses);
  };

  const handleOnStartValidatingCreditCard = async (): Promise<void> => {
    showModal({
      value: ShowModalPayment.VALIDATING_CREDIT_CARD,
      newTitle: '',
    });

    try {
      const response = await api.post<CardValidationResponse[]>(
        'client/my-account/card/validate',
        cartPayments,
      );
      setCardValidationResponses(response.data);
    } catch (error) {
      const messagesValidationError: string[] = [];
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<CustomError, CustomError>;
        if (axiosError.response && axiosError.response.data) {
          if (axiosError.response.data.details && axiosError.response.data.details.length > 0) {
            axiosError.response.data.details.forEach(data => {
              messagesValidationError.push(data);
            });
          } else if (axiosError.response.data.message) {
            messagesValidationError.push(axiosError.response.data.message);
          } else {
            messagesValidationError.push('Houve um erro ao processar o seu cartão');
          }
        } else {
          messagesValidationError.push('Houve um erro ao processar o seu cartão');
        }
      } else {
        messagesValidationError.push('Houve um erro ao processar o seu cartão');
      }
      const newCardValidationResponses: CardValidationResponse[] = [];
      if (validatingCreditCard && validatingCreditCard.length > 0) {
        validatingCreditCard.forEach(data => {
          const cardValidationResponse: CardValidationResponse = {
            status: CardValidationResponseStatus.ERROR,
            card: data,
            value: '',
            messages: messagesValidationError,
          };
          newCardValidationResponses.push(cardValidationResponse);
        });
      }
      setCardValidationResponses(newCardValidationResponses);
    }

    setTimeout(() => {
      setValidateStatus(CreditCardValidationStatus.VALIDATE_DATA);
    }, 200);
  };

  const create3DSAuthRequest = async (): Promise<void> => {
    try {
      const payload: CartPayment[] = [];
      cardsCheckout.forEach(data => {
        const value =
          paymentType === PaymentType.CREDIT_CARD
            ? Number(cart.totalValueWithPaymentFee)
            : Number(unmask(data.value));
        const valueString = onlyNumbers(value.toFixed(2));
        const cartPayment: CartPayment = {
          paymentType: PaymentType.CREDIT_CARD,
          installments: Number(data.installments),
          clientCardId: data.id,
          paymentValue: Number(valueString),
        };
        payload.push(cartPayment);
      });
      const { data } = await api.post<SessionResponse>('/event/session', payload);
      const decrypted = decrypt(data.requests);
      const requests = JSON.parse(decrypted) as Authenticate3DSRequest[];
      const newAuth3DSResponses: Auth3DSResponse[] = [];
      requests.forEach(request => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cardsCheckout.length; i++) {
          const value =
            paymentType === PaymentType.CREDIT_CARD
              ? Number(cart.totalValueWithPaymentFee)
              : Number(unmask(cardsCheckout[i].value));
          const valueString = onlyNumbers(value.toFixed(2));
          const paymentValue = Number(valueString);
          if (
            request.data.paymentMethod.card.number === cardsCheckout[i].number &&
            request.data.amount.value === paymentValue
          ) {
            const auth3DSResponse: Auth3DSResponse = {
              cardId: cardsCheckout[i].id,
              session: data.session,
              installments: Number(cardsCheckout[i].installments),
              paymentValue,
              request,
            };
            newAuth3DSResponses.push(auth3DSResponse);
          }
        }
      });
      setAuth3DSResponses(newAuth3DSResponses);
      setAuth3DSStatus(Auth3DSStatus.START);
      /*
      setTimeout(() => {
        dispatch(setLoading(false));
        create3DSAuth(newAuth3DSResponses[0]);
      }, 300);
      */
    } catch {
      dispatch(setLoading(false));
    }
  };

  const create3DSAuth = async (auth3DSResponse: Auth3DSResponse): Promise<void> => {
    const { REACT_APP_PAGSEGURO_ENV } = process.env;
    PagSeguro.setUp({
      session: auth3DSResponse.session,
      env: REACT_APP_PAGSEGURO_ENV as 'PROD' | 'SANDBOX',
    });
    try {
      dispatch(setLoading(false));
      setShowLoadingPage3DSValidation(true);
      const response = await PagSeguro.authenticate3DS(auth3DSResponse.request);
      const newAuth3DSResponses: Auth3DSResponse[] = [];
      auth3DSResponses.forEach(item => {
        if (item.cardId === auth3DSResponse.cardId) {
          const newAuth3DSResponse = {
            ...item,
            response,
          };
          newAuth3DSResponses.push(newAuth3DSResponse);
        } else {
          newAuth3DSResponses.push(item);
        }
      });
      setAuth3DSResponses(newAuth3DSResponses);
      setAuth3DSStatus(Auth3DSStatus.AUTH_SUCCESS);
    } catch (error) {
      const newError = error as Authenticate3DSError;
      const newAuth3DSResponses: Auth3DSResponse[] = [];
      auth3DSResponses.forEach(item => {
        if (item.cardId === auth3DSResponse.cardId) {
          const newAuth3DSResponse = {
            ...item,
            error: newError,
          };
          newAuth3DSResponses.push(newAuth3DSResponse);
        } else {
          newAuth3DSResponses.push(item);
        }
      });
      setAuth3DSResponses(newAuth3DSResponses);
      setAuth3DSStatus(Auth3DSStatus.AUTH_ERROR);
    }
  };

  const metaPixel = (
    idPixel: string,
    orderId: string,
    totalValue: number,
    currency?: string,
  ): void => {
    const dynamicMetaPixel = MetaPixel(idPixel);
    dynamicMetaPixel.trackPageView();
    dynamicMetaPixel.purchase(orderId, totalValue, currency);
  };

  const connectToWebSocket = (orderId: string): void => {
    const socket = new WebSocket(
      `wss://api.bancadoingresso.com.br/api/websocket/v1/pix/${orderId}`,
    );
    socket.onopen = (): void => {
      // eslint-disable-next-line no-console
      console.log('Connected to the web socket');
    };
    socket.onmessage = (m: MessageEvent<string>) => {
      if (m.data === 'APPROVED') {
        showModal({
          value: ShowModalPayment.CREDIT_CARD_PAYMENT,
          newTitle: '',
        });
      }
    };
    socket.onclose = () => {
      connectToWebSocket(orderId);
    };
    socket.onerror = () => {
      connectToWebSocket(orderId);
    };
  };

  const createOrderObject = async (payments: CartPayment[]): Promise<void> => {
    const order: Cart = {
      events: [],
      amountItens: cart.amountItens,
      totalValue: cart.totalValue,
      totalValueWithFee: cart.totalValueWithFee,
      totalValueWithPaymentFee: cart.totalValueWithPaymentFee,
      discountCoupon:
        cart.discountCoupon && cart.discountCoupon.id ? cart.discountCoupon : undefined,
      voucher: cart.voucher && cart.voucher.id ? cart.voucher : undefined,
      payments,
      pdvId: cart.pdvId,
    };

    const paymentValueFee = order.totalValueWithPaymentFee - order.totalValueWithFee;
    const paymentFee = (paymentValueFee * 100) / order.totalValue / 100 + 1;

    cart.events.forEach(event => {
      const cartEventTickets: CartEventTicket[] = [];
      if (event.sections && event.sections.length > 0) {
        event.sections.forEach(section => {
          if (section.tickets && section.tickets.length > 0) {
            section.tickets.forEach(ticket => {
              const cartEventTicket: CartEventTicket = {
                id: ticket.id,
                unitValue: ticket.unitValue,
                fee: ticket.fee,
                paymentFee,
                totalValue: ticket.totalValue,
                isHalfPrice: ticket.isHalfPrice,
                lables: ticket.labels && ticket.labels.length > 0 ? ticket.labels : undefined,
                quantity: ticket.quantity,
                userDatas: ticket.userDatas,
              };
              cartEventTickets.push(cartEventTicket);
            });
          }
        });
      }
      const cartEvent: CartEvent = {
        eventId: event.id,
        tickets: cartEventTickets && cartEventTickets.length > 0 ? cartEventTickets : undefined,
      };

      if (event.groups && event.groups.length > 0) {
        event.groups.forEach(group => {
          if (group.products && group.products.length > 0) {
            const cartProducts: CartProduct[] = [];
            group.products.forEach(product => {
              const cartProduct: CartProduct = {
                id: product.id,
                unitValue: product.unitValue,
                fee: product.fee,
                paymentFee,
                totalValue: product.totalValue,
                quantity: product.quantity,
              };
              cartProducts.push(cartProduct);
            });
            if (cartProducts && cartProducts.length > 0) {
              cartEvent.products = cartProducts;
            }
          }

          if (group.combos && group.combos.length > 0) {
            const cartCombos: CartCombo[] = [];
            group.combos.forEach(combo => {
              const cartCombo: CartCombo = {
                id: combo.id,
                unitValue: combo.unitValue,
                fee: combo.fee,
                paymentFee,
                totalValue: combo.totalValue,
                quantity: combo.quantity,
              };
              cartCombos.push(cartCombo);
            });
            if (cartCombos && cartCombos.length > 0) {
              cartEvent.combos = cartCombos;
            }
          }
        });
      }
      order.events.push(cartEvent);
    });
    try {
      let response: AxiosResponse<OrderResponse[]> | undefined;
      if (cameFromAdm) {
        response = await sendOrderToAPIAdm(order.payments);
      } else {
        response = await sendOrderToAPIEcommerce(order);
      }
      if (response.data && response.data.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].paymentMessage) {
            setMessagePayment(response.data[i].paymentMessage);
            showModal({
              value: ShowModalPayment.PAYMENT_NOTMADE,
              newTitle: '',
            });
            return;
          }
        }
      }

      setOrderResponse(response.data);
      if (response.status === 201) {
        /**
         * TODO
         * Refactor this code after event ends
         */
        if (cart.events && cart.events.length > 0) {
          // Pixel
          // eslint-disable-next-line no-plusplus
          for (let e = 0; e < cart.events.length; e++) {
            if (
              cart.events[e].pixel &&
              cart.events[e].pixel !== undefined &&
              cart.events[e].pixel !== null
            ) {
              const pixel = cart.events[e].pixel as string;

              if (pixel !== null && pixel !== undefined && pixel.trim().length > 0) {
                metaPixel(pixel, cart.events[e].id, cart.totalValueWithPaymentFee, 'BRL');
              }
            }
          }
        }
        if (
          paymentType === PaymentType.CREDIT_CARD ||
          paymentType === PaymentType.TWO_CREDIT_CARDS
        ) {
          showModal({
            value: ShowModalPayment.CREDIT_CARD_PAYMENT,
            newTitle: '',
          });
        } else if (paymentType === PaymentType.PIX) {
          showModal({
            value: ShowModalPayment.PIX_PAYMENT,
            newTitle: '',
          });
          connectToWebSocket(response.data[0].orderId);
        } else {
          showModal({
            value: ShowModalPayment.TICKET_PAYMENT,
            newTitle: '',
          });
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<CustomError, CustomError>;
        if (axiosError.response && axiosError.response.data) {
          if (axiosError.response.data.details && axiosError.response.data.details.length > 0) {
            setMessageError(axiosError.response.data.details);
          } else if (axiosError.response.data.message) {
            setMessageError([axiosError.response.data.message]);
          } else {
            setMessageError([
              'Houve um erro ao processar o seu cartão. Entre em contato com o seu banco para obter mais informações.',
            ]);
          }
        }
      }
      showModal({
        value: ShowModalPayment.PAYMENT_ERROR,
        newTitle: '',
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSendOrderCreditCard = async (): Promise<void> => {
    dispatch(setLoading(true));
    const payments: CartPayment[] = [];
    // eslint-disable-next-line no-plusplus
    for (let a = 0; a < auth3DSResponses.length; a++) {
      const { REACT_APP_PAGSEGURO_TOKEN } = process.env;
      // eslint-disable-next-line no-await-in-loop
      const response = await api.get<CardClientCrypt>(
        `client/my-account/card/${auth3DSResponses[a].cardId}`,
      );
      const cardDB = JSON.parse(decrypt(response.data.data)) as CardDb;
      const expirationDate = cardDB.expirationDate.split('/');
      const card = PagSeguro.encryptCard({
        publicKey: REACT_APP_PAGSEGURO_TOKEN,
        holder: cardDB.cardholderName,
        number: onlyNumbers(cardDB.number),
        expMonth: expirationDate[0],
        expYear: expirationDate[1],
        securityCode: cardDB.cvv,
      });

      const encrypted = card.encryptedCard;

      const newPayment: CartPayment = {
        paymentType,
        installments: Number(auth3DSResponses[a].installments),
        clientCardId: auth3DSResponses[a].cardId,
        encrypted,
        authenticationId: auth3DSResponses[a].response
          ? auth3DSResponses[a].response?.id
          : undefined,
        paymentValue: auth3DSResponses[a].paymentValue / 100,
      };
      payments.push(newPayment);
    }
    await createOrderObject(payments);
  };

  const checkAuth3DS = (): void => {
    let auth3DSResponse: Auth3DSResponse | undefined;
    let message: string | undefined;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < auth3DSResponses.length; i++) {
      if (!auth3DSResponses[i].response && !auth3DSResponses[i].error) {
        auth3DSResponse = auth3DSResponses[i];
        break;
      } else if (auth3DSResponses[i].response) {
        const status = auth3DSResponses[i].response?.status as
          | 'AUTH_FLOW_COMPLETED'
          | 'AUTH_NOT_SUPPORTED'
          | 'CHANGE_PAYMENT_METHOD'
          | 'REQUIRE_CHALLENGE';
        if (status === 'CHANGE_PAYMENT_METHOD') {
          message = 'Validação 3DS solicitou alterar a forma de pagamento!';
          break;
        }
      }
    }
    if (message) {
      setShowLoadingPage3DSValidation(false);
      setMessageError([message]);
      showModal({
        value: ShowModalPayment.PAYMENT_ERROR,
        newTitle: '',
      });
    } else if (auth3DSResponse) {
      create3DSAuth(auth3DSResponse);
    } else {
      setShowLoadingPage3DSValidation(false);
      handleSendOrderCreditCard();
    }
  };

  const handleSendOrder = async (): Promise<void> => {
    if (!enableSendOrder) {
      return;
    }
    if (isFormValidPersonalInfo()) {
      dispatch(setLoading(true));
      if (
        !client.cpf ||
        !client.email ||
        client.email !== formDataPersonalInfo.email ||
        !client.cellPhone ||
        // client.cellPhone != formDataPersonalInfo.phone ||
        !client.birthDate ||
        !client.motherName ||
        client.motherName !== formDataPersonalInfo.motherName ||
        !client.name ||
        client.name !== formDataPersonalInfo.name
      ) {
        try {
          await savePersonalInfo();
        } catch {
          dispatch(setLoading(false));
          toast.error('Erro ao Atualizar os dados pessoais');
          return;
        }
      }
      if (isFormValidAddress()) {
        if (!client.address) {
          try {
            await saveAddress();
          } catch {
            dispatch(setLoading(false));
            toast.error('Erro ao Atualizar endereço');
            return;
          }
        }
        const checkPayment = await checkPaymentCreditCard();
        if (!(checkPayment instanceof Array) && checkPayment) {
          if (
            paymentType === PaymentType.CREDIT_CARD ||
            paymentType === PaymentType.TWO_CREDIT_CARDS
          ) {
            // eslint-disable-next-line no-unreachable-loop, no-plusplus
            for (let index = 0; index < cardsCheckout.length; index++) {
              if (isCardCheckoutValid(index)) {
                create3DSAuthRequest();
                return;
              }
              dispatch(setLoading(false));
              toast.warn('Selecione um cartão');
              return;
            }
          } else {
            const payments: CartPayment[] = [];
            if (paymentType === PaymentType.BANK_SLIP || paymentType === PaymentType.PIX) {
              const newPayment: CartPayment = {
                paymentType,
                installments: 1,
                paymentValue: cart.totalValueWithPaymentFee,
              };
              payments.push(newPayment);
            }

            if (payments.length === 0) {
              dispatch(setLoading(false));
              toast.warn('Escolha a forma de pagamento!');
              return;
            }

            createOrderObject(payments);
          }
        } else {
          dispatch(setLoading(false));
          if (checkPayment instanceof Array) {
            setMessageError(checkPayment);
            showModal({
              value: ShowModalPayment.PAYMENT_ERROR,
              newTitle: '',
            });
          } else {
            setTimeout(() => {
              setValidateStatus(CreditCardValidationStatus.CREATE_ORDER);
            }, 300);
          }
        }
      } else {
        toast.warn('Informe seu endereço!');
        const el = window.document.getElementById('divEndereco') as HTMLElement;
        el.scrollIntoView();
      }
    } else {
      toast.warn('Informe seus dados pessoais!');
      const el = window.document.getElementById('divCPF') as HTMLElement;
      el.scrollIntoView();
    }
  };

  const handleOnCheckValidationCreditCardData = async (): Promise<void> => {
    try {
      let showError = false;
      const newCardValidationResponses: CardValidationResponse[] = [];
      if (cardValidationResponses && cardValidationResponses.length > 0) {
        cardValidationResponses.forEach(data => {
          if (data.status === CardValidationResponseStatus.APPROVED) {
            const valueString = data.value as string;
            if (valueString && valueString.trim().length > 0) {
              const value = Number(valueString.replace(/\./g, '').replace(',', '.'));
              if (value <= 0) {
                newCardValidationResponses.push({
                  ...data,
                  errorInput: 'Valor obrigatório',
                });
                showError = true;
              } else {
                newCardValidationResponses.push(data);
              }
            } else {
              newCardValidationResponses.push({
                ...data,
                errorInput: 'Valor obrigatório',
              });
              showError = true;
            }
          } else {
            newCardValidationResponses.push(data);
          }
        });
      }
      if (showError) {
        setCardValidationResponses(newCardValidationResponses);
      } else {
        showModal({
          value: ShowModalPayment.VALIDATING_CREDIT_CARD,
          newTitle: '',
        });
        const response = await api.post<CardValidationResponse[]>(
          'client/my-account/card/validate/check',
          cardValidationResponses,
        );
        setCardValidationResponses(response.data);
        setTimeout(() => {
          setValidateStatus(CreditCardValidationStatus.VALIDATED);
        }, 200);
      }
    } catch (error) {
      const messagesValidationError: string[] = [];
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<CustomError, CustomError>;
        if (axiosError.response && axiosError.response.data) {
          if (axiosError.response.data.details && axiosError.response.data.details.length > 0) {
            axiosError.response.data.details.forEach(data => {
              messagesValidationError.push(data);
            });
          } else if (axiosError.response.data.message) {
            messagesValidationError.push(axiosError.response.data.message);
          } else {
            messagesValidationError.push('Houve um erro ao processar o seu cartão');
          }
        } else {
          messagesValidationError.push('Houve um erro ao processar o seu cartão');
        }
      } else {
        messagesValidationError.push('Houve um erro ao processar o seu cartão');
      }
      const newCardValidationResponses: CardValidationResponse[] = [];
      if (validatingCreditCard && validatingCreditCard.length > 0) {
        validatingCreditCard.forEach(data => {
          const cardValidationResponse: CardValidationResponse = {
            status: CardValidationResponseStatus.ERROR,
            card: data,
            value: '',
            messages: messagesValidationError,
          };
          newCardValidationResponses.push(cardValidationResponse);
        });
      }
      setCardValidationResponses(newCardValidationResponses);
      setTimeout(() => {
        setValidateStatus(CreditCardValidationStatus.VALIDATED);
      }, 200);
    }
  };

  const handleOnValidateCreditCard = async (): Promise<void> => {
    showModal({
      value: ShowModalPayment.VALIDATING_CREDIT_CARD,
      newTitle: '',
    });
    let showSucess = true;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cardValidationResponses.length; i++) {
      if (cardValidationResponses[i].status !== CardValidationResponseStatus.APPROVED) {
        showSucess = false;
        break;
      }
    }
    setTimeout(() => {
      if (showSucess) {
        showModal({
          value: ShowModalPayment.VALIDATED,
          newTitle: '',
        });
      } else {
        showModal({
          value: ShowModalPayment.VALIDATED_ERROR,
          newTitle: '',
        });
      }
    }, 200);
  };

  const handleGoToTickets = (): void => {
    const newCart = {
      events: [],
      discountCoupon: undefined,
      voucher: undefined,
      amountItens: 0,
      totalValue: 0,
      totalValueWithFee: 0,
      originalValueWithPaymentFee: 0,
      totalValueWithPaymentFee: 0,
    };
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    history('/ingressos');
  };

  const handleCopyText = (text: string): void => {
    navigator.clipboard.writeText(text);
    toast.info('Conteudo copiado com sucesso!');
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputAddress(FormInputNameAddress.state)(cepResponse.state);
      onChangeFormInputAddress(FormInputNameAddress.city)(cepResponse.city);
      onChangeFormInputAddress(FormInputNameAddress.district)(cepResponse.neighborhood);
      onChangeFormInputAddress(FormInputNameAddress.street)(cepResponse.street);
    }
  };

  const controllerPersonalinfo: UseFormControl = {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  };

  const handleToogleModal = (): void => {
    onToggle();
    if (paymentType === PaymentType.BANK_SLIP || paymentType === PaymentType.PIX) {
      handleGoToTickets();
    }
  };

  const controllerModalPayment: UseModalControl = {
    visible,
    title,
    shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: handleToogleModal,
    onSetVisible,
  };

  const onSubmitAddress = async (): Promise<void> => {
    if (isFormValidAddress()) {
      try {
        dispatch(setLoading(true));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerAddress: UseFormControl = {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  };

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const onSubmitAddCreditCard = async (): Promise<void> => {
    if (isFormValidAddCreditCard()) {
      if (
        validateCVV(
          formDataAddCreditCard[FormInputNameCreditCard.number],
          formDataAddCreditCard[FormInputNameCreditCard.cvv],
        )
      ) {
        try {
          dispatch(setLoading(true));
          const expDate = formDataAddCreditCard[FormInputNameCreditCard.date].split('/');

          const card: Card = {
            holder: formDataAddCreditCard[FormInputNameCreditCard.name],
            number: onlyNumbers(formDataAddCreditCard[FormInputNameCreditCard.number]),
            expMonth: expDate[0],
            expYear: `20${expDate[1]}`,
            securityCode: formDataAddCreditCard[FormInputNameCreditCard.cvv],
            document: onlyNumbers(formDataAddCreditCard[FormInputNameCreditCard.document]),
            cardType: getCreditCardLabel(formDataAddCreditCard[FormInputNameCreditCard.number]),
          };
          const encrypted = encrypt(JSON.stringify(card));

          const cardClientCrypt: CardClientCrypt = {
            data: encrypted,
          };
          const responseCardClient = await api.post<CardClient>(
            'client/my-account/card',
            cardClientCrypt,
          );
          await getUserData();
          const responseEncrypted = await api.get(
            `client/my-account/card/${responseCardClient.data.id}`,
          );
          const cardDB = JSON.parse(decrypt(responseEncrypted.data.data)) as CardDb;
          setTimeout(() => {
            setCardSelected(cardDB);
          }, 500);
          resetFormAddCreditCard();
          onSetVisible(false);
          dispatch(setLoading(false));
          toast.success('Cartão adicionado com sucesso!');
        } catch {
          dispatch(setLoading(false));
        }
      } else {
        toast.warn('Código de segurança inválido!');
      }
    }
  };

  const controllerAddCreditCard: UseFormControl = {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  };

  const onShowAddCreditCard = (): void => {
    showModal({
      value: ShowModalPayment.ADD_CREDIT_CARD_PAYMENT,
      newTitle: 'Adicionar novo cartão',
    });
  };

  const onShowDeleteCreditCard = (id: string): void => {
    setCardId(id);
    showModal({
      value: ShowModalPayment.CONFIRM_DELETE_CREDIT_CARD_PAYMENT,
      newTitle: '',
    });
  };

  const onSubmitDeleteCreditCard = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      await api.delete<CardClient>(`client/my-account/card/${cardId}`);
      await getUserData();
      onSetVisible(false);
      dispatch(setLoading(false));
      toast.success('Cartão removido com sucesso!');
    } catch {
      dispatch(setLoading(false));
    }
  };

  const controllerCreditCard: ControllerCreditCard = {
    list: client.cards ? client.cards : [],
    onShowAddCreditCard,
    onShowDeleteCreditCard,
    onSubmitDeleteCreditCard,
    controllerAddCreditCard,
  };

  const handleSelectCar = async (id: string, index: number): Promise<void> => {
    const response = await api.get(`client/my-account/card/${id}`);
    const cardDB = JSON.parse(decrypt(response.data.data)) as CardDb;
    const list: CardCheckout[] = [];
    cardsCheckout.forEach((data, i) => {
      if (i === index) {
        const newCardCheckout: CardCheckout = {
          ...data,
          id,
          number: cardDB.number,
          expDate: cardDB.expirationDate as string,
          securityCodeDb: cardDB.cvv,
          holder: cardDB.cardholderName,
          document: cardDB.cardholderCpf,
        };
        list.push(newCardCheckout);
      } else {
        list.push(data);
      }
    });
    setCardsCheckout(list);
    setCardSelected(cardDB);
  };

  const handleOnCheckoutValidateCreditCard = async (index: number): Promise<void> => {
    if (
      cardsCheckout[index].validationValue &&
      cardsCheckout[index].validationValue.trim().length > 0
    ) {
      const value = Number(unmask(cardsCheckout[index].validationValue));
      if (value > 0) {
        dispatch(setLoading(true));
        try {
          const payload: ValidateCardcheckout = {
            cardId: cardsCheckout[index].id,
            value,
          };
          await api.post('/client/my-account/card/validate/checkout', payload);
          toast.success('Cartão validado com sucesso!');
          getUserData();
        } catch (error) {
          const messagesValidationError: string[] = [];
          if (error instanceof AxiosError) {
            const axiosError = error as AxiosError<CustomError, CustomError>;
            if (axiosError.response && axiosError.response.data) {
              if (axiosError.response.data.details && axiosError.response.data.details.length > 0) {
                axiosError.response.data.details.forEach(data => {
                  messagesValidationError.push(data);
                });
              } else if (axiosError.response.data.message) {
                messagesValidationError.push(axiosError.response.data.message);
              } else {
                messagesValidationError.push('Houve um erro ao processar o seu cartão');
              }
            } else {
              messagesValidationError.push('Houve um erro ao processar o seu cartão');
            }
          } else {
            messagesValidationError.push('Houve um erro ao processar o seu cartão');
          }
          messagesValidationError.forEach(data => {
            toast.warning(data);
          });
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        cardsCheckout[index].validationValueError = 'Campo Obrigatório';
        setCardsCheckout(cardsCheckout);
      }
    } else {
      cardsCheckout[index].validationValueError = 'Campo Obrigatório';
      setCardsCheckout(cardsCheckout);
    }
  };

  useEffect(() => {
    if (auth3DSStatus === Auth3DSStatus.START && auth3DSResponses.length > 0) {
      create3DSAuth(auth3DSResponses[0]);
    } else if (auth3DSStatus === Auth3DSStatus.AUTH_SUCCESS && auth3DSResponses.length > 0) {
      let check = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < auth3DSResponses.length; i++) {
        if (auth3DSResponses[i].response) {
          check = true;
          break;
        }
      }
      if (check) {
        setTimeout(() => {
          checkAuth3DS();
        }, 300);
      }
    } else if (auth3DSStatus === Auth3DSStatus.AUTH_ERROR) {
      setMessageError(['Erro ao criar sessão!']);
      showModal({
        value: ShowModalPayment.PAYMENT_ERROR,
        newTitle: '',
      });
    }
  }, [auth3DSStatus, auth3DSResponses]);

  useEffect(() => {
    if (validateStatus === CreditCardValidationStatus.CREATE_ORDER) {
      handleOnStartValidatingCreditCard();
    } else if (validateStatus === CreditCardValidationStatus.VALIDATE_DATA) {
      showModal({
        value: ShowModalPayment.VALIDATE_CREDIT_CARD,
        newTitle: '',
      });
    } else if (validateStatus === CreditCardValidationStatus.VALIDATED) {
      handleOnValidateCreditCard();
    }
  }, [validateStatus]);

  useEffect(() => {
    if (signed) {
      getUserData();
    }
  }, [signed]);

  useEffect(() => {
    if (cart && cart.events && cart.events.length > 0) {
      checkPaymentOptions();
    }
  }, [cart]);

  useEffect(() => {
    if (element) {
      getUserData();
    }
  }, [element]);

  useEffect(() => {
    loadCart();
    checkPageLoaded();
  }, []);

  return (
    <>
      {showLoadingPage3DSValidation ? (
        <Loading3DSValidation />
      ) : (
        <>
          {cart && cart.events.length > 0 && (
            <CheckoutContainer
              cart={cart}
              loadedUserData={loadedUserData}
              isWithin24Hours={isWithin24Hours}
              controllerPersonalinfo={controllerPersonalinfo}
              onChangeTransferDocument={onChangeTransferDocument}
              onCheckCpfTransfer={handleOnCheckCpfTransfer}
              incrementTicket={incrementTicket}
              decrementTicket={decrementTicket}
              incrementProduct={incrementProduct}
              decrementProduct={decrementProduct}
              incrementCombo={incrementCombo}
              decrementCombo={decrementCombo}
              removeCupom={removeCupom}
              paymentType={paymentType}
              cards={cardsCheckout}
              payment={payment}
              controllerModalPayment={controllerModalPayment}
              qrCodeImage={
                orderResponse && orderResponse.length > 0 ? orderResponse[0].fileURL : ''
              }
              qrCodeText={orderResponse && orderResponse.length > 0 ? orderResponse[0].content : ''}
              boleto={orderResponse && orderResponse.length > 0 ? orderResponse[0].fileURL : ''}
              barcode={orderResponse && orderResponse.length > 0 ? orderResponse[0].content : ''}
              messagePayment={messagePayment}
              messageError={messageError}
              toCurrency={toCurrency}
              cameFromAdm={cameFromAdm}
              onSetPaymentType={handleSetPaymentType}
              onChangeCreditCard={handleChangeCreditCard}
              onSendOrder={handleSendOrder}
              onGoToTickets={handleGoToTickets}
              onCopyText={handleCopyText}
              onCheckCpf={handleOnCheckCpf}
              controllerAddress={controllerAddress}
              onChangeCEP={handleOnChangeCEP}
              onSubmitAddress={onSubmitAddress}
              onSubmitAddCard={onSubmitAddCreditCard}
              controllerCreditCard={controllerCreditCard}
              cardSelected={cardSelected}
              onCardSelected={handleSelectCar}
              setCardSelectedInt={handleCardSelectedInt}
              onValidateCreditCard={handleOnCheckValidationCreditCardData}
              validatingCreditCards={validatingCreditCard}
              cardValidationResponses={cardValidationResponses}
              onChangeInputValidate={handleOnChangeInputValidate}
              checkoutValidateCreditCard={handleOnCheckoutValidateCreditCard}
              enableSendOrder={enableSendOrder}
            />
          )}
        </>
      )}
    </>
  );
};

export const CheckoutScreen: React.FC = (): JSX.Element => {
  const handleOnSendOrderToAPIEcommerce = async (
    order: Cart,
  ): Promise<AxiosResponse<OrderResponse[]>> => {
    const response = await api.post<OrderResponse[]>('event/order', order);
    return response;
  };
  const handleOnSendOrderToAPIAdm = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payments: CartPayment[],
  ): Promise<AxiosResponse<OrderResponse[]>> => {
    throw new AxiosError('Not Implemented!');
  };
  return (
    <CheckoutMain
      cameFromAdm={false}
      sendOrderToAPIEcommerce={handleOnSendOrderToAPIEcommerce}
      sendOrderToAPIAdm={handleOnSendOrderToAPIAdm}
    />
  );
};
