import React, { Fragment, JSX, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { InputTextCupom, Button } from '@/components';
import { useDispatch } from 'react-redux';
// import { setCart, useCart } from '@/redux/cart/cartSlice';
import { EventTicketSite } from '@/features/event/screens/Event';
import { useSidebar } from '@/hooks/useSidebar';
import { NavigateFunction } from 'react-router-dom';
import CheckDiscount from '@/model/CheckDiscount';
// import { States } from '@/helpers/common/states';
import { api, AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import { setLoading } from '@/redux/loading/loadingSlice';
import { getItem, setItem } from '@/helpers/common/localStorage';
import { REACT_CART } from '@/utils/config';
import MetaPixel from '@/utils/meta/metaPixel';
import TicketSite from '@/model/TicketSite';
import DiscountCoupon from '@/model/DiscountCoupon';
import { ProductIncrementResume } from '../../auth/components/ProductIncrementResume';
import { CartEmptyContent } from './CartEmptyContent';
import Cart from './Cart';
import { TicketIncrementResume } from '../../auth/components/TicketIncrementResume';

interface Props {
  history: NavigateFunction;
}

export const CartEventContent: React.FC<Props> = ({ history }): JSX.Element => {
  const [cart, setCart] = useState<Cart>({
    events: [],
    checkDiscount: undefined,
    discountCoupon: undefined,
    voucher: undefined,
    amountItens: 0,
    totalValue: 0,
    totalValueWithFee: 0,
    originalValueWithPaymentFee: 0,
    totalValueWithPaymentFee: 0,
    pdvId: undefined,
  });
  const [clear, setClear] = useState(false);
  const [coupon, setCoupon] = useState('');
  // const { cart } = useSelector(useCart);
  // const [state, setState] = useState<States>(States.default);
  const dispatch = useDispatch();
  const { onCloseSidebar } = useSidebar();

  const loadCart = (): void => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
      setCart(cartVariable);
    } else {
      toast.error('Carrinho vazio');
    }
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const handleClear = (): void => {
    const newCart: Cart = {
      events: [],
      checkDiscount: undefined,
      discountCoupon: undefined,
      voucher: undefined,
      amountItens: 0,
      totalValue: 0,
      totalValueWithFee: 0,
      originalValueWithPaymentFee: 0,
      totalValueWithPaymentFee: 0,
    };
    // dispatch(setCart(newCart));
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    setClear(true);
  };

  const incrementTicket = (item: EventTicketSite): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        checkDiscount: undefined,
        discountCoupon: undefined,
        voucher: undefined,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as Cart;
    newCart.events.forEach(event => {
      event.sections.forEach(section => {
        section.tickets.forEach(ticket => {
          if (ticket.id === item.id) {
            if (ticket.id === item.id && ticket.isHalfPrice === item.isHalfPrice) {
              ticket.userDatas.push({} as CartEventTicketUserData);
              // eslint-disable-next-line no-param-reassign
              ticket.quantity += 1;
              newCart.amountItens += 1;
              newCart.totalValue = Number(newCart.totalValue) + Number(item.unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) + Number(item.unitValue) * Number(item.fee);
              newCart.originalValueWithPaymentFee = newCart.totalValueWithFee;
              newCart.totalValueWithPaymentFee = newCart.totalValueWithFee;
            }
          }
        });
      });
    });
    // dispatch(setCart(newCart));
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const getCart = (): Cart => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        checkDiscount: undefined,
        discountCoupon: undefined,
        voucher: undefined,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as Cart;

    return newCart;
  };

  const removeCoupon = (): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    if (cart.discountCoupon || cart.voucher) {
      newCart.originalValueWithPaymentFee = newCart.totalValueWithFee;
      newCart.totalValueWithPaymentFee = newCart.totalValueWithFee;
    }
    newCart.checkDiscount = undefined;
    newCart.discountCoupon = undefined;
    newCart.voucher = undefined;
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const decrementTicket = (item: EventTicketSite): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;

    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        checkDiscount: undefined,
        discountCoupon: undefined,
        voucher: undefined,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as Cart;
    let countTicketDiscount = 0;

    let countEvent = 0;
    let indexEvent = 0;
    let willSpliceEvent = false;
    newCart.events.forEach(data => {
      let countSection = 0;
      let indexSection = 0;
      let willSpliceSection = false;
      data.sections.forEach(section => {
        let countTicket = 0;
        let indexTicket = 0;
        let willSpliceTicket = false;
        section.tickets.forEach(ticket => {
          if (
            newCart.checkDiscount &&
            newCart.checkDiscount.tickets &&
            newCart.checkDiscount.tickets.length > 0 &&
            newCart.discountCoupon
          ) {
            // eslint-disable-next-line no-plusplus
            for (let td = 0; td < newCart.checkDiscount.tickets.length; td++) {
              if (
                newCart.checkDiscount.tickets[td].discountCoupon &&
                newCart.checkDiscount.tickets[td].discountCoupon?.id === newCart.discountCoupon.id
              ) {
                if (ticket.id === newCart.checkDiscount.tickets[td].id) {
                  countTicketDiscount = ticket.quantity - 1;
                  break;
                }
              }
            }
          }
          if (ticket.id === item.id && ticket.unitValue === item.unitValue) {
            ticket.userDatas.splice(ticket.userDatas.length - 1, 1);
            // eslint-disable-next-line no-param-reassign
            ticket.quantity -= 1;
            newCart.amountItens -= 1;
            newCart.totalValue = Number(newCart.totalValue) - Number(item.unitValue);
            newCart.totalValueWithFee =
              Number(newCart.totalValueWithFee) - Number(item.unitValue) * Number(item.fee);
            newCart.originalValueWithPaymentFee =
              Number(newCart.totalValueWithPaymentFee) - Number(item.unitValue) * Number(item.fee);
            newCart.totalValueWithPaymentFee =
              Number(newCart.totalValueWithPaymentFee) - Number(item.unitValue) * Number(item.fee);
            if (ticket.quantity === 0) {
              willSpliceTicket = true;
              indexTicket = Number(countTicket);
            }
          }
          // eslint-disable-next-line no-plusplus
          countTicket++;
        });

        if (willSpliceTicket) {
          section.tickets.splice(indexTicket, 1);
        }
        if (section.tickets.length === 0) {
          willSpliceSection = true;
          indexSection = Number(countSection);
        }
        // eslint-disable-next-line no-plusplus
        countSection++;
      });
      if (willSpliceSection) {
        data.sections.splice(indexSection, 1);
      }

      if (data.sections.length === 0) {
        willSpliceEvent = true;
        indexEvent = Number(countEvent);
      }
      // eslint-disable-next-line no-plusplus
      countEvent++;
    });
    if (willSpliceEvent) {
      newCart.events.splice(indexEvent, 1);
    }
    if (newCart.amountItens === 0) {
      handleClear();
    } else {
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
      if (
        countTicketDiscount === 0 &&
        newCart.checkDiscount &&
        newCart.checkDiscount.tickets &&
        newCart.checkDiscount.tickets.length > 0 &&
        newCart.discountCoupon
      ) {
        newCart.checkDiscount = undefined;
        newCart.discountCoupon = undefined;
        removeCoupon();
      }
    }
  };

  const incrementProduct = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.products[index].quantity += 1;
              newCart.amountItens += 1;
              newCart.totalValue =
                Number(newCart.totalValue) + Number(group.products[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
            }
          });
        }
      }
    });
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const decrementProduct = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            let willSplice = false;
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.products[index].quantity -= 1;
              newCart.amountItens -= 1;
              newCart.totalValue =
                Number(newCart.totalValue) - Number(group.products[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.products[index].unitValue) * Number(group.products[index].fee);
              if (group.products[index].quantity === 0) {
                willSplice = true;
              }
            }
            if (willSplice) {
              group.products.splice(index, 1);
            }
          });
        }
      }
    });
    if (newCart.amountItens === 0) {
      handleClear();
    } else {
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
    }
  };

  const incrementCombo = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.combos[index].quantity += 1;
              newCart.amountItens += 1;
              newCart.totalValue =
                Number(newCart.totalValue) + Number(group.combos[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) +
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
            }
          });
        }
      }
    });
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
  };

  const decrementCombo = (eventId: string, groupId: string, index: number): void => {
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const newCart = getCart();
    newCart.events.forEach(event => {
      if (event.id === eventId) {
        if (event.groups && event.groups.length > 0) {
          event.groups.forEach(group => {
            let willSplice = false;
            if (group.id === groupId) {
              // eslint-disable-next-line no-param-reassign
              group.combos[index].quantity -= 1;
              newCart.amountItens -= 1;
              newCart.totalValue =
                Number(newCart.totalValue) - Number(group.combos[index].unitValue);
              newCart.totalValueWithFee =
                Number(newCart.totalValueWithFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.originalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              newCart.totalValueWithPaymentFee =
                Number(newCart.totalValueWithPaymentFee) -
                Number(group.combos[index].unitValue) * Number(group.combos[index].fee);
              if (group.combos[index].quantity === 0) {
                willSplice = true;
              }
            }
            if (willSplice) {
              group.combos.splice(index, 1);
            }
          });
        }
      }
    });
    if (newCart.amountItens === 0) {
      handleClear();
    } else {
      setItem(REACT_CART as string, JSON.stringify(newCart));
      setCart(newCart);
    }
  };
  const addCoupon = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const json = JSON.stringify(cart);
      const newCart = JSON.parse(json) as Cart;
      let found = false;
      // eslint-disable-next-line no-plusplus, no-unreachable-loop
      for (let c = 0; c < cart.events.length; c++) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await api.get<CheckDiscount>(
          `/event/${cart.events[c].id}/discount-coupon/${coupon}`,
        );
        let ticket: TicketSite = undefined as unknown as TicketSite;
        if (data.tickets && data.tickets.length > 0) {
          newCart.checkDiscount = data;
          // eslint-disable-next-line no-plusplus
          for (let dt = 0; dt < data.tickets.length; dt++) {
            if (cart.events && cart.events.length > 0) {
              // eslint-disable-next-line no-plusplus
              for (let e = 0; e < cart.events.length; e++) {
                if (cart.events[e].id === cart.events[c].id) {
                  if (cart.events[e].sections && cart.events[e].sections.length > 0) {
                    let foundSection = false;
                    // eslint-disable-next-line no-plusplus
                    for (let s = 0; s < cart.events[e].sections.length; s++) {
                      if (
                        cart.events[e].sections[s].tickets &&
                        cart.events[e].sections[s].tickets.length > 0
                      ) {
                        // eslint-disable-next-line no-plusplus
                        for (let t = 0; t < cart.events[e].sections[s].tickets.length; t++) {
                          if (cart.events[e].sections[s].tickets[t].id === data.tickets[dt].id) {
                            foundSection = true;
                            if (data.tickets[dt].discountCoupon) {
                              newCart.discountCoupon = {
                                ...(data.tickets[dt].discountCoupon as DiscountCoupon),
                              };
                              ticket = data.tickets[dt];
                            }
                            found = true;
                            break;
                          }
                        }
                      }
                      if (foundSection) {
                        break;
                      }
                    }
                  }
                  break;
                }
              }
            }
            if (found) {
              break;
            }
          }
        }
        if (ticket && ticket.discountCoupon) {
          const discountCoupon = ticket.discountCoupon as DiscountCoupon;
          let couponUsed = false;
          // eslint-disable-next-line no-plusplus
          newCart.events.forEach(event => {
            event.sections.forEach(section => {
              section.tickets.forEach(sectionTicket => {
                if (sectionTicket.id === ticket.id) {
                  /* const unitValues =
                    Number(sectionTicket.unitValue) * Number(sectionTicket.quantity); */
                  const discountValue =
                    (Number(sectionTicket.unitValue) * Number(discountCoupon.discount)) / 100;
                  newCart.originalValueWithPaymentFee -= discountValue;
                  newCart.totalValueWithPaymentFee -= discountValue;
                  if (newCart.discountCoupon && !couponUsed) {
                    newCart.discountCoupon.discount = discountValue;
                    couponUsed = true;
                  }
                }
              });
            });
          });
        } else if (data.voucher) {
          newCart.originalValueWithPaymentFee -= Number(data.voucher.value);
          newCart.totalValueWithPaymentFee -= Number(data.voucher.value);
          newCart.voucher = data.voucher;
        }
        // dispatch(setCart(newCart));
        setItem(REACT_CART as string, JSON.stringify(newCart));
        setCart(newCart);
        setCoupon('');
        found = true;
        break;
      }
      if (!found) {
        toast.warning('Código de cupom inválido!');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const err = error as AxiosError;
        if (err.status === 404) {
          toast.warning('Código de cupom inválido!');
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const goToHome = (): void => {
    onCloseSidebar();
    history('/');
  };

  const goToCheckout = (): void => {
    if (cart && cart.events && cart.events.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let e = 0; e < cart.events.length; e++) {
        if (
          cart.events[e].pixel &&
          cart.events[e].pixel !== undefined &&
          cart.events[e].pixel !== null
        ) {
          const pixel = cart.events[e].pixel as string;
          if (pixel !== null && pixel !== undefined && pixel.trim().length > 0) {
            const dynamicMetaPixel = MetaPixel(pixel);
            dynamicMetaPixel.addCart(
              cart.events[e].id,
              cart.events[e].name,
              cart.totalValueWithPaymentFee,
              cart.amountItens,
            );
          }
        }
      }
    }

    onCloseSidebar();
    history('/checkout');
  };

  useEffect(() => {
    loadCart();
  }, []);

  return (
    <>
      {clear && <CartEmptyContent />}
      {!clear && (
        <div className="cart-content relative w-full pt-[30px] pb-[120px] px-5 md:px-[85px]">
          <div className="flex flex-wrap justify-between items-center">
            <h5 className="text-[24px] font-normal leading-[28px] text-neutro-b-400">Seu pedido</h5>
            <button
              className="font-dmsans text-base text-neutro-b-400"
              onClick={() => handleClear()}
            >
              Limpar
            </button>
            <p className="text-left text-base font-normal leading-5 text-neutro-w-600 mt-[5px]">
              Confirme se o seu pedido esta correto
            </p>
          </div>
          <div className="cart-itens mt-[30px]">
            {cart &&
              cart.events &&
              cart.events.map(event => (
                <div key={event.id} className="event">
                  <header className="grid grid-cols-[115px_1fr] items-center gap-5 mb-2">
                    <div className="relative">
                      <img
                        className="w-[115px] h-[91px] object-cover rounded-md"
                        src={event.imageUrl}
                        alt="Event"
                      />
                      <div className="absolute rounded-md top-0 w-full h-full bg-card-default hover:bg-card-hover transition-all duration-200 ease-in-out"></div>
                    </div>
                    <div className="text-left">
                      <h4 className="text-neutral-b-400 font-dmsans font-medium text-[21px]">
                        {event.name}
                      </h4>
                      <div className="flex flex-wrap xs:flex-nowrap items-center font-base">
                        <div className="font-dmsans font-normal text-neutro-w-600 uppercase w-[50px] block">
                          {dayjs(event.date).locale('pt-br').format('DD MMM')}
                        </div>
                        <div className="block w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full"></div>
                        <div className="font-dmsans font-normal text-neutro-w-600 uppercase">
                          {dayjs(event.date).format('HH:mm')}
                        </div>
                        <div className="block w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full"></div>

                        <div className="font-dmsans font-normal text-neutro-w-600">
                          <span
                            className="inline truncate text-ellipsis overflow-hidden"
                            title={`${event.address.city}/${event.address.state}`}
                          >
                            {event.address.city}
                          </span>
                          <span
                            className="inline"
                            title={`${event.address.city}/${event.address.state}`}
                          >
                            /{event.address.state}
                          </span>
                        </div>
                      </div>
                    </div>
                  </header>
                  {event.sections &&
                    event.sections.map((section, index) => (
                      <Fragment key={section.section.id + index}>
                        {section.tickets.map((ticket, ixTicket) => (
                          <>
                            {ticket.quantity > 0 && (
                              <div
                                key={`${ixTicket}-${ticket.id}`}
                                className=" cart-item w-full border-b border-neutro-w-200"
                              >
                                <div>
                                  <TicketIncrementResume
                                    key={ticket.id}
                                    item={ticket}
                                    increment={incrementTicket}
                                    decrement={decrementTicket}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </Fragment>
                    ))}
                  {event.groups &&
                    event.groups.length > 0 &&
                    event.groups.map(group => (
                      <Fragment key={group.id}>
                        {group.products &&
                          group.products.length > 0 &&
                          group.products.map((product, index) => (
                            <>
                              {product.quantity > 0 && (
                                <div
                                  key={product.id}
                                  className=" cart-item w-full border-b border-neutro-w-200"
                                >
                                  <div>
                                    <ProductIncrementResume
                                      key={product.id}
                                      index={index}
                                      eventId={event.id}
                                      groupId={group.id}
                                      name={product.name}
                                      count={product.amount}
                                      quantity={product.quantity}
                                      unitValue={product.unitValue}
                                      fee={product.fee}
                                      increment={incrementProduct}
                                      decrement={decrementProduct}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        {group.combos &&
                          group.combos.length > 0 &&
                          group.combos.map((combo, index) => (
                            <>
                              {combo.quantity > 0 && (
                                <div
                                  key={combo.id}
                                  className=" cart-item w-full border-b border-neutro-w-200"
                                >
                                  <div>
                                    <ProductIncrementResume
                                      key={combo.id}
                                      index={index}
                                      eventId={event.id}
                                      groupId={group.id}
                                      name={combo.name}
                                      count={combo.amount}
                                      quantity={combo.quantity}
                                      unitValue={combo.unitValue}
                                      fee={combo.fee}
                                      increment={incrementCombo}
                                      decrement={decrementCombo}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                      </Fragment>
                    ))}
                </div>
              ))}

            <div className="my-[20px]">
              <InputTextCupom
                name="document"
                label="Cupom de desconto (apenas um cupom por compra)"
                placeholder="ABCD1234"
                maxLength={30}
                value={coupon}
                onChange={e => setCoupon(e.target.value)}
                error={''}
                onApply={() => addCoupon()}
                onRemove={removeCoupon}
                discountCoupon={cart.discountCoupon}
                voucher={cart.voucher}
              />
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-heading font-dmsans font-normal">
                Total de itens: <span className="font-bold">{cart.amountItens}</span>
              </div>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-heading font-dmsans font-normal">
                Subtotal: <span className="font-bold">{toCurrency(cart.totalValue)}</span>
              </div>
            </div>
            {cart.discountCoupon && (
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Desconto:{' '}
                  <span className="font-bold">- {toCurrency(cart.discountCoupon.discount)}</span>
                </div>
              </div>
            )}
            {cart.voucher && (
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Desconto: <span className="font-bold">- {toCurrency(cart.voucher.value)}</span>
                </div>
              </div>
            )}
            <div className="flex justify-between items-center mb-1">
              <div className="text-heading font-dmsans font-normal">
                Taxa:{' '}
                <span className="font-bold">
                  {toCurrency(cart.totalValueWithFee - cart.totalValue)}
                </span>
              </div>
            </div>
            <hr className="my-5" />
            <div className="flex justify-between items-center mb-1">
              <div className="text-heading font-dmsans font-normal">
                Valor total:{' '}
                <span className="font-bold">{toCurrency(cart.totalValueWithPaymentFee)}</span>
              </div>
            </div>
            <Button
              type="submit"
              title="Fechar pedido"
              buttonStyle="primary"
              size="xlg"
              className="w-full my-[20px]"
              onClick={goToCheckout}
            />
            <Button
              type="submit"
              title="Continuar comprando"
              buttonStyle="outlined"
              size="xlg"
              className="w-full"
              onClick={() => goToHome()}
            />
          </div>
        </div>
      )}
    </>
  );
};
