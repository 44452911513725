import React, { Fragment, JSX } from 'react';
import Section from '@/model/Section';
import { Area, Trash } from '@/assets/icons';
import { Switch } from '@/components';
import EventSiteHeader from '@/model/EventSiteHeader';
import { EventDate, EventDateSectionSite, EventGroup } from '../screens/Event';
import { SliderProductArea } from './SliderTicketProduct';
import { ProductIncrement } from './ProductIncrement';
import SliderGroup from './SliderGroup';
import { ShouldShowModalProps } from '../types';

interface StateProps {
  event?: EventDate;
  sectionId: string;
  showProducts: boolean;
  groupId: string;
  showMap: boolean;
  groupName?: string;
  dates: EventSiteHeader[];
}

interface DispathProps {
  onSelectSection: (section: Section) => void;
  onIncrementTicket: (index: number) => void;
  onDecrementTicket: (index: number) => void;
  onShowModal: (props: ShouldShowModalProps) => void;
  getEventKey: (sectionId: string, ticketId: string) => string;
  onCheckShowProducts: (checked: boolean) => void;
  onIncrementProduct: (index: number) => void;
  onDecrementProduct: (index: number) => void;
  onSelectGroup: (group: EventGroup) => void;
  onIncrementCombo: (index: number) => void;
  onDecrementCombo: (index: number) => void;
  onHandleMapSection: (sections: EventDateSectionSite[]) => EventDateSectionSite[];
  onModal: (sectionId: string, tickectId: string, hasMap: boolean) => void;
  onRemoveLabel: (index: number, chartSectionId: string, label: string) => void;
}

type Props = StateProps & DispathProps;

export const EventSelectContent: React.FC<Props> = ({
  event,
  sectionId,
  showProducts,
  groupId,
  showMap,
  groupName,
  dates,
  onSelectSection,
  onIncrementTicket,
  onDecrementTicket,
  onShowModal,
  getEventKey,
  onCheckShowProducts,
  onIncrementProduct,
  onDecrementProduct,
  onIncrementCombo,
  onDecrementCombo,
  onSelectGroup,
  onHandleMapSection,
  onModal,
  onRemoveLabel,
}): JSX.Element => {
  const toCurrency = (productValue: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(productValue);
  };

  const mountText = (productValue: number, productFee: number): string => {
    let s = '';
    if (productFee && productFee > 0) {
      s = ` + Taxa ${toCurrency(productValue * productFee - productValue)}`;
    }
    return s;
  };
  return (
    <>
      <section className="event__area mb-10">
        <h2 className="text-heading text-xl font-dmsans font-normal flex">
          <img src={Area} alt="Selecione o dia" className="fill-heading mr-3" />
          <span>Selecione o setor</span>
        </h2>
        <div>
          {event && event.sections && (
            <SliderProductArea
              items={showMap ? onHandleMapSection(event.sections) : event.sections}
              handleOnClick={onSelectSection}
              showModal={onShowModal}
              getEventKey={getEventKey}
              onModal={onModal}
            />
          )}
        </div>
        {event &&
          event.sections &&
          event.sections.length > 0 &&
          event.sections.map(section => (
            <>
              {section.section.id === sectionId && (
                <div className="area-result mt-8 space-y-4">
                  {groupName &&
                    section.tickets &&
                    section.tickets.length > 0 &&
                    section.tickets && (
                      <Fragment>
                        <ProductIncrement
                          index={0}
                          name={section.tickets[0].name}
                          value={section.tickets[0].unitValue}
                          fee={section.tickets[0].fee}
                          count={section.tickets[0].count}
                          quantity={section.tickets[0].quantity}
                          hasMap={section.tickets[0].hasMap}
                          renameTable={showMap}
                          groupName={groupName}
                          eventKey={
                            section.tickets[0].hasMap
                              ? getEventKey(section.section.id, section.tickets[0].id)
                              : undefined
                          }
                          startDate={event.startDate}
                          endDate={event.endDate}
                          increment={onIncrementTicket}
                          decrement={onDecrementTicket}
                          showModal={onShowModal}
                        />
                        {dates &&
                          dates.length > 0 &&
                          dates.map(date => (
                            <Fragment key={`dateId-${date.id}`}>
                              {date.eventDate &&
                                date.eventDate.sections &&
                                date.eventDate.sections.length > 0 &&
                                date.eventDate.sections.map(sct => (
                                  <Fragment key={`sct.section.id-${sct.section.id}`}>
                                    {sct.tickets &&
                                      sct.tickets.length > 0 &&
                                      sct.tickets.map((tkt, tktIndex) => (
                                        <Fragment key={`tkt.id-${tkt.id}`}>
                                          {tkt.labels &&
                                            tkt.labels.length > 0 &&
                                            tkt.labels.map((label, index) => (
                                              <div
                                                key={`${index}-${label}`}
                                                className="flex justify-between items-center border border-[#D9D9D9] rounded-[5px] py-[18px] px-[25px]"
                                              >
                                                <div className="space-y-1">
                                                  <div
                                                    className="font-dmsans font-normal text-base
                                              leading-[21px] text-label"
                                                  >
                                                    {label}
                                                  </div>
                                                  <div className="font-dmsans text-xs text-heading">
                                                    {`${toCurrency(tkt.unitValue)}${mountText(tkt.unitValue, tkt.fee)}`}
                                                  </div>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                  <button
                                                    onClick={() =>
                                                      onRemoveLabel(tktIndex, sct.section.id, label)
                                                    }
                                                    className="w-[18px] h-[18px] flex justify-center items-center"
                                                  >
                                                    <img
                                                      src={Trash}
                                                      className="fill-cyan-500"
                                                      alt="Remover produto"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            ))}
                                        </Fragment>
                                      ))}
                                  </Fragment>
                                ))}
                            </Fragment>
                          ))}
                      </Fragment>
                    )}
                  {!groupName &&
                    section.tickets &&
                    section.tickets.length > 0 &&
                    section.tickets.map((ticket, index) => (
                      <ProductIncrement
                        key={`${index}-${ticket.id}`}
                        index={index}
                        name={ticket.name}
                        value={ticket.unitValue}
                        fee={ticket.fee}
                        count={ticket.count}
                        quantity={ticket.quantity}
                        hasMap={ticket.hasMap}
                        renameTable={showMap}
                        groupName={groupName}
                        eventKey={
                          ticket.hasMap ? getEventKey(section.section.id, ticket.id) : undefined
                        }
                        startDate={event.startDate}
                        endDate={event.endDate}
                        increment={onIncrementTicket}
                        decrement={onDecrementTicket}
                        showModal={onShowModal}
                      />
                    ))}
                </div>
              )}
            </>
          ))}
      </section>
      {event && event.groups && event.groups.length > 0 && (
        <section>
          <div className="flex justify-between items-center">
            <div className="text-heading font-dmsans font-normal">Deseja adicionar produtos?</div>
            <div>
              <Switch
                value={`${showProducts}`}
                checked={showProducts}
                onChange={() => onCheckShowProducts(!showProducts)}
              />
            </div>
          </div>
          {showProducts && event.groups && event.groups.length > 0 && (
            <div className="event__area mb-10">
              <div>
                {event.groups && showProducts && (
                  <SliderGroup groups={event.groups} handleOnClick={onSelectGroup} />
                )}
              </div>
              {event.groups.map(group => (
                <>
                  {group.id === groupId && (
                    <div className="area-result mt-8 space-y-4">
                      {group.products &&
                        group.products.length > 0 &&
                        group.products.map((product, index) => (
                          <ProductIncrement
                            key={`${index}-${product.id} `}
                            index={index}
                            name={product.name}
                            value={product.unitValue}
                            fee={product.fee}
                            count={product.amount}
                            quantity={product.quantity}
                            hasMap={false}
                            renameTable={false}
                            increment={onIncrementProduct}
                            decrement={onDecrementProduct}
                            showModal={onShowModal}
                          />
                        ))}
                      {group.combos &&
                        group.combos.length > 0 &&
                        group.combos.map((combo, index) => (
                          <ProductIncrement
                            key={`${index}-${combo.id}`}
                            index={index}
                            name={combo.name}
                            value={combo.unitValue}
                            fee={combo.fee}
                            count={combo.amount}
                            quantity={combo.quantity}
                            hasMap={false}
                            renameTable={false}
                            increment={onIncrementCombo}
                            decrement={onDecrementCombo}
                            showModal={onShowModal}
                          />
                        ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
};
